<template>
  <Dialogue
    :is-visible="isVisible"
    is-fullscreen
  >
    <v-card>
      <v-card-title class="no-padding">
        <v-card-text class="no-padding">
          <TemperatureChart
            v-if="!isLoading && !hasError && hasData"
            :asset-name="assetName"
            :ble-id="bleId"
            :temperature-data="temperatureData"
            :asset-space-data="spaceData"
            :is-full-screen="true"
            :full-screen-window-height="fullScreenWindowHeight"
            :include-download="includeDownload"
            :thresholds="thresholds"
            @closeFullScreen="$emit('close')"
          />
          <div v-if="!isLoading && !hasError && !hasData">
            No data
          </div>
        </v-card-text>
      </v-card-title>
    </v-card>
    <resize-observer @notify="setFullScreenHeight" />
  </Dialogue>
</template>
<script>
import Dialogue from '../../atoms/dialogue/dialogue';
import TemperatureChart from '../../atoms/charts/temperature-chart';

export default {
  components: {
    Dialogue,
    TemperatureChart,
  },
  props: {
    isVisible: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    hasData: { type: Boolean, default: false },
    assetName: { type: String, default: '' },
    bleId: { type: Number, default: 0 },
    temperatureData: { type: Array, default: () => [] },
    spaceData: { type: Array, default: () => [] },
    thresholds: { type: Array, default: () => ([]) },
    includeDownload: { type: Boolean, default: false },
  },
  data() {
    return {
      fullScreenWindowHeight: 0,
    };
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.fullScreenWindowHeight = window.innerHeight;
      }
    },
  },
  methods: {
    setFullScreenHeight() {
      this.fullScreenWindowHeight = window.innerHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.no-padding {
  padding: 0 !important;
}
</style>
