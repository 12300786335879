<template>
  <RouteWithChildren :parent-level="3">
    <ItemDetail
      :key="explorerToggleOptions.length"
      ref="itemDetail"
      :item-title="shipment ? shipment.trackingRef : ''"
      :item="shipment || {}"
      :rows="rows"
      :is-loading="isLoading"
      :is-loading-in-background="isLoadingInBackground"
      :explorer-toggle-options="explorerToggleOptions"
      @close="close"
    />
  </RouteWithChildren>
</template>
<script>
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import ItemDetail from '../../components/organisms/items/item-detail';

// exploreToggleOptions
import ShipmentTimeline from '../../components/organisms/shipments/shipment-timeline';
import MapWithPopup from '../../components/molecules/map/map-with-popup';
import AssetsTable from '../../components/organisms/assets/assets-table';

import {
  routeNames, alertIcons, itemDetailTabSlugs, mapItemTypes, mapEntityTypes, icons,
} from '../../constants';
import { shipments, facilities, assets } from '../../data/shipments-demo';
import { constructTime, constructDate } from '../../helpers/date-formatting-helper';

function roundTimeToNearest15Mins(date) {
  const coeff = 1000 * 60 * 15;
  return new Date(Math.round(date.getTime() / coeff) * coeff);
}

function constructEtaValue(dateIso, destination) {
  if (!dateIso) return '';

  const date = new Date(dateIso);

  const { timeZone } = destination.where.facility;

  return `${constructDate(date, false, timeZone)}, ${constructTime(roundTimeToNearest15Mins(date), timeZone)} (${timeZone})`;
}

export default {
  components: {
    RouteWithChildren,
    ItemDetail,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      isLoadingInBackground: false,
    };
  },
  computed: {
    isLoading() { return false; },
    locations() {
      return (this.shipment.locations || {}).items || [];
    },
    explorerToggleOptions() {
      return [
        {
          displayName: 'Timeline',
          slug: itemDetailTabSlugs.timeline,
          component: ShipmentTimeline,
          componentProps: {
            shipmentId: this.id,
          },
        },
        {
          displayName: 'Map',
          slug: itemDetailTabSlugs.map,
          component: MapWithPopup,
          componentProps: {
            locations: this.locations.map((location) => ({
              ...location,
              properties: {
                id: this.shipment.id,
                name: this.shipment.trackingRef,
                timestamp: location.timestamp,
              },
            })),
            destination: {
              ...this.shipment.destination.where.facility.location,
              properties: {
                itemType: mapItemTypes.destination,
                type: mapEntityTypes.facility,
                icon: icons.facility,
                name: this.shipment.destination.where.facility.name,
                eta: constructEtaValue(
                  (this.shipment.destination.when || {}).estimated,
                  this.shipment.destination,
                ),
              },
            },
            alerts: this.locations
              .filter((loc) => loc.isAlert)
              .map((loc) => {
                const alert = this.shipment.alerts.find((a) => a.timestamp === loc.timestamp);
                return {
                  ...loc,
                  properties: {
                    type: mapItemTypes.alert,
                    name: alert.message,
                    icon: alertIcons[alert.category][alert.type],
                    iconColor: 'warning',
                  },
                };
              }),
          },
        },
        {
          displayName: 'Assets',
          slug: itemDetailTabSlugs.assets,
          component: AssetsTable,
          componentProps: {
            smallerGapBottom: true,
            withTitle: false,
            displayHasLabel: false,
            noBorder: true,
            itemIds: assets.filter((a) => a.consignmentId === this.shipment.id).map((a) => a.id),
          },
        },
      ];
    },
    shipment() {
      const shipment = shipments.find((s) => s.id === this.id) || {};
      return {
        ...shipment,
        origin: {
          ...shipment.origin,
          where: {
            ...shipment.origin.where,
            facility: facilities
              .find((facility) => facility.id === shipment.origin.where.facility.id),
          },
        },
        destination: {
          ...shipment.destination,
          where: {
            ...shipment.destination.where,
            facility: facilities
              .find((facility) => facility.id === shipment.destination.where.facility.id),
          },
        },
      };
    },
    rows() {
      return [
        { name: 'Tracking ref', value: this.shipment.trackingRef },
        { name: 'Asset count', value: this.shipment.pieceCount },
        { name: 'Origin', value: this.shipment.origin.where.facility.name },
        {
          name: 'Destination',
          value: this.shipment.destination.where.facility.name,
        },
        {
          name: 'ETA',
          value: constructEtaValue(
            (this.shipment.destination.when || {}).estimated,
            this.shipment.destination,
          ),
        },
        {
          name: 'Status',
          value: {
            value: this.shipment.alerts.length > 0 ? this.shipment.alerts[0].message : 'OK',
            icon: this.shipment.alerts.length > 0 ? alertIcons[this.shipment.alerts[0].category][this.shipment.alerts[0].type] : '',
            iconColor: 'warning',
          },
        },
      ];
    },
  },
  watch: {
    id() { this.loadData(); },
  },
  created() { this.loadData(); },
  methods: {
    close() {
      this.$router.push({ name: routeNames.shipments.list, query: this.$route.query });
    },
    loadData() {
      this.isLoadingInBackground = true;
      setTimeout(() => {
        this.isLoadingInBackground = false;
      }, 800);
    },
  },
};
</script>
