import { webToApp } from '../../mobileApp/appInterface';
import { tLabelDataDownloadEnum, tLabelDiscoveryEnum } from '../../constants';
import { createNotification, notificationTypes } from '../../helpers/notification-helper';

const getDefaultState = () => ({
  discoveryStatus: null,
  downloadStatuses: {}, // keyed by bleId
  downloadStatusesWatchId: 0,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_DISCOVERY_STATUS(state, status) { state.discoveryStatus = status; },
    RESET_DISCOVERY_STATUS(state) { state.discoveryStatus = null; },
    SET_DOWNLOAD_STATUS(state, status) {
      if (status.bleId) {
        state.downloadStatuses[status.bleId] = status;
        state.downloadStatusesWatchId += 1;
      }
    },
    RESET_DOWNLOAD_STATUS_FOR_LABELS(state, bleIds) {
      bleIds.forEach((bleId) => {
        delete state.downloadStatuses[bleId];
      });
      state.downloadStatusesWatchId += 1;
    },
  },
  actions: {
    // webToApp...
    startDiscovery(context) {
      context.commit('RESET_DISCOVERY_STATUS');
      webToApp.tLabel.startDiscovery();
    },
    stopDiscovery() {
      webToApp.tLabel.stopDiscovery();
    },
    startDownload(context, { bleIds, timeout }) {
      context.commit('RESET_DOWNLOAD_STATUS_FOR_LABELS', bleIds);
      webToApp.tLabel.startDownload(bleIds, timeout);
    },
    stopDownload(_, bleIds) {
      webToApp.tLabel.stopDownload(bleIds);
    },
    // appToWeb...
    setDiscoveryStatus(context, status) {
      switch (status.status) {
        case tLabelDiscoveryEnum.bluetoothUnsupported:
          context.commit(
            'notifications/ADD_NOTIFICATION',
            createNotification('This device does not support Bluetooth', notificationTypes.bluetoothUnsupported, null, true, true),
            { root: true },
          );
          break;
        case tLabelDiscoveryEnum.bluetoothOff:
          context.commit(
            'notifications/ADD_NOTIFICATION',
            createNotification('Please switch Bluetooth on', notificationTypes.bluetoothOff, null, true, true),
            { root: true },
          );
          break;
        case tLabelDiscoveryEnum.bluetoothUnauthorized:
          context.commit(
            'notifications/ADD_NOTIFICATION',
            createNotification('Please grant permission to use Bluetooth', notificationTypes.bluetoothUnauthorized, null, true, true),
            { root: true },
          );
          break;
        case tLabelDiscoveryEnum.error:
          context.commit(
            'notifications/ADD_NOTIFICATION',
            createNotification(`An error has occurred while scanning for labels${status.message ? `. ${status.message}` : ''}`, notificationTypes.error, null, true, true),
            { root: true },
          );
          break;

        default:
          // nothing
      }

      context.commit('SET_DISCOVERY_STATUS', status);
    },
    setDownloadStatus(context, status) { context.commit('SET_DOWNLOAD_STATUS', status); },
    async setData(context, data) {
      context.commit('SET_DOWNLOAD_STATUS', {
        bleId: data.bleId,
        status: tLabelDataDownloadEnum.complete,
      });
      context.dispatch('assets/addLabelTemperatureReadings', { bleId: data.bleId, readings: data.readings }, { root: true });
    },
  },
  getters: {},
};
