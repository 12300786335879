<template>
  <MainWithDetailLayout
    :is-loading="isLoading"
    :main-route-name="mainRoutetName"
    :is-main-full-width="!isDetailScreen"
    :hide-detail="isLoading"
  >
    <VerticalContainer v-if="!isLoading">
      <ShipmentsTable :selected-id="$route.params.id" />
      <template #buttons>
        <Button
          :on-click="displayShipmentsMap"
          text="Map"
        />
      </template>
    </VerticalContainer>
  </MainWithDetailLayout>
</template>
<script>
import MainWithDetailLayout from '../../components/organisms/layout/main-with-detail-layout';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import ShipmentsTable from '../../components/organisms/shipments/shipments-table';
import Button from '../../components/atoms/button/button';

import { routeNames } from '../../constants';

export default {
  name: 'ShipmentsList',
  components: {
    MainWithDetailLayout,
    VerticalContainer,
    ShipmentsTable,
    Button,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    mainRoutetName() { return routeNames.shipments.list; },
    isDetailScreen() { return this.$route.name !== routeNames.shipments.list; },
  },
  created() {
    // Redirect to home if shipments demo is disabled
    if (!this.$root.workspaceFeatures.shipmentsDemo) {
      this.$router.push({ name: routeNames.home });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    },
    displayShipmentsMap() {
      this.$router.push({ name: routeNames.shipments.map }).catch(() => { });
    },
  },
};
</script>
