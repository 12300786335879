<template>
  <VerticalContainer>
    <div class="download-temperature-container">
      <Title
        :title="asset.name"
        :style="'width:100%'"
      />
      <div class="messages">
        <bounce-loader
          :loading="isLoading"
          :color="loaderColour"
          size="100px"
        />
        <div class="message-container">
          {{ title }}
        </div>
        <div
          v-if="!hasError && message"
          class="message-container"
        >
          {{ message }}
        </div>
        <ErrorMessage
          v-else
          :error-message="message"
        />
      </div>
    </div>
    <template #buttons>
      <Button
        v-if="hasError"
        text="Retry"
        :on-click="clickRetry"
      />
      <Button
        text="Cancel"
        @click="clickCancel"
      />
    </template>
    <YesNoDialogue
      :is-visible="isDialogueVisible"
      title="Are you sure you want to cancel getting temperature data?"
      @no="() => isDialogueVisible = false"
      @yes="goToAssetDetails"
    />
  </VerticalContainer>
</template>

<script>
import BounceLoader from 'vue-spinner/src/BounceLoader';
import VerticalContainer from '../../components/organisms/layout/vertical-container';
import Button from '../../components/atoms/button/button';
import ErrorMessage from '../../components/atoms/message/error-message';
import { itemDetailTabSlugs, routeNames, tLabelDataDownloadEnum } from '../../constants';
import YesNoDialogue from '../../components/molecules/dialogues/yes-no-dialogue';
import Title from '../../components/atoms/table-title/table-title';
import variables from '../../styles/_variables.scss';

function getStartingData() {
  return {
    isLoading: true,
    title: 'Getting temperature data...',
    message: '',
    hasError: false,
  };
}

export default {
  name: 'DownloadTemperature',
  components: {
    BounceLoader,
    VerticalContainer,
    Title,
    Button,
    ErrorMessage,
    YesNoDialogue,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      isDialogueVisible: false,
      ...getStartingData(),
    };
  },
  computed: {
    loaderColour() {
      return variables.teal1;
    },
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.id);
    },
    downloadStatusesWatchId() {
      return this.$store.state.mobileApp.tLabels.downloadStatusesWatchId;
    },
    temperatureData() {
      return this.$store.state.mobileApp.temperatureData;
    },
  },
  watch: {
    downloadStatusesWatchId() {
      const status = this.$store.state.mobileApp.tLabels.downloadStatuses[this.asset.bleId];

      if (!status) return;

      this.message = status.message || '';
      this.inProgress = false;

      switch (status.status) {
        case tLabelDataDownloadEnum.discovering:
          this.title = 'Scanning for label...';
          break;
        case tLabelDataDownloadEnum.connecting:
          this.title = 'Connecting to label...';
          break;
        case tLabelDataDownloadEnum.discoveringServices:
          this.title = 'Discovering label services...';
          break;
        case tLabelDataDownloadEnum.discoveringCharacteristics:
          this.title = 'Discovering label characteristics...';
          break;
        case tLabelDataDownloadEnum.downloading:
          this.title = 'Downloading label data...';
          break;
        case tLabelDataDownloadEnum.complete:
          this.title = 'Download complete';
          this.goToLabelTemperature();
          break;
        default:
          this.hasError = true;
          this.title = 'Error';
          this.message = this.message || 'Some unknown error has occurred';
          this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    this.stopDownload();
  },
  created() {
    if (!this.$store.getters.isMobileApp) {
      this.$router.replace({ name: routeNames.assets.downloadTemperatureRequireApp });
    } else {
      this.startDownload();
    }
  },
  methods: {
    resetData() {
      const data = getStartingData();
      Object.keys(data).forEach((key) => {
        this[key] = data[key];
      });
    },
    startDownload() {
      this.resetData();
      this.$store.dispatch('mobileApp/tLabels/startDownload', {
        bleIds: [this.asset.bleId],
        timeout: 180, // 3 mins
      });
    },
    stopDownload() {
      this.$store.dispatch('mobileApp/tLabels/stopDownload', [this.asset.bleId]);
    },
    clickRetry() {
      this.startDownload();
    },
    clickCancel() {
      if (this.isLoading) {
        this.isDialogueVisible = true;
      } else {
        this.goToAssetDetails();
      }
    },
    goToAssetDetails() {
      this.$router.replace({ name: routeNames.assets.detail });
    },
    goToLabelTemperature() {
      this.$router.push({
        name: routeNames.assets.detail,
        query: { tab: itemDetailTabSlugs.temperature },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
 .download-temperature-container {
  height: 100%;
  color: $black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 40px 0;
  background-color: $white;
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);

  .messages {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .message-container {
    margin: 20px 0;
  }
}
</style>
