<template>
  <v-timeline-item
    :color="color"
    small
    right
    class="timeline-item"
  >
    <template #opposite>
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          cols="5"
          :sm="isExpandContent ? 4 : 5"
          class="text-center"
        >
          <strong>{{ startDate }}</strong>
          <div>{{ startTime }}</div>
          <div v-if="timeZone && !hideTimeZone">
            {{ timeZone }}
          </div>
        </v-col>
        <v-col
          class="text-center"
          cols="1"
        >
          <span v-if="displayRightSide && endDate">-</span>
        </v-col>
        <v-col
          v-if="displayRightSide"
          cols="5"
          :sm="isExpandContent ? 4 : 5"
          class="text-center"
        >
          <strong>{{ endDate }}</strong>
          <div>{{ endTime }}</div>
          <div v-if="!hideTimeZone">
                  &nbsp;
          </div>
        </v-col>
      </v-row>
    </template>
    <template
      v-if="displayArrow"
      #icon
    >
      <v-icon small>
        mdi-arrow-up
      </v-icon>
    </template>
    <v-row
      justify="center"
    >
      <v-col>
        <div class="event-details">
          <v-icon
            v-if="icon"
            class="event-type-icon"
          >
            {{ icon }}
          </v-icon>
          <div class="event-detail">
            <strong>{{ title }}</strong>
            <div class="text-caption">
              {{ text }}
            </div>
            <div v-if="link">
              <router-link :to="link.to">
                {{ link.text || "View details" }}
              </router-link>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>
<script>
export default {
  props: {
    color: { type: String, default: 'primary' },
    title: { type: String, required: true },
    icon: { type: String, default: null },
    text: { type: String, default: '' },
    link: { type: Object, default: null },
    timeZone: { type: String, default: null },
    startDate: { type: String, required: true },
    startTime: { type: String, required: true },
    endDate: { type: String, default: null },
    endTime: { type: String, default: null },
    hideRightIfSame: { type: Boolean, default: false },
    displayArrow: { type: Boolean, default: false },
    hideTimeZone: { type: Boolean, default: false },
    isExpandContent: { type: Boolean, default: false },
  },
  computed: {
    displayRightSide() {
      return !this.hideRightIfSame
        || (this.endDate && (this.endDate !== this.startDate || this.endTime !== this.startTime));
    },
  },
};
</script>
<style lang="scss" scoped>
.timeline-item {
  text-align: left;
  .event-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    .event-type-icon {
      margin-right: 15px;
    }

    .event-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}
</style>
