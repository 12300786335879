/* eslint-disable import/prefer-default-export */

import { constructTime, constructDate, getBrowserTimeZone } from './date-formatting-helper';

const colour = {
  online: '#87C98A',
  offline: '#EB5048',
};

const BROWSER_TIME_ZONE = getBrowserTimeZone();

export function constructGatewayStatusTimelineItemsForSpace(
  currentGatewayStatus,
  facilityTimeZone,
  statusHistory = { items: [] },
) {
  const timeZone = facilityTimeZone || BROWSER_TIME_ZONE;
  const restructuredStatusHistory = statusHistory.items.map((sh, index) => {
    const newSh = {};
    newSh.color = sh.status === 'Online' ? colour.online : colour.offline;
    const fromDateSh = new Date(sh.from);
    newSh.startDate = constructDate(fromDateSh, false, timeZone);
    newSh.startTime = constructTime(fromDateSh, timeZone);

    // if response has 'to' attribute
    if (sh.to && (index !== 0 || (sh.status !== currentGatewayStatus))) {
      const toDateSh = new Date(sh.to);
      newSh.endDate = constructDate(toDateSh, false, timeZone);
      newSh.endTime = constructTime(toDateSh, timeZone);
    }
    newSh.title = sh.status;
    newSh.timeZone = timeZone;

    return newSh;
  });
  return restructuredStatusHistory;
}
