import NotificationsList from '../views/notifications/NotificationsList';
import { routeNames } from '../constants';

export default [
  {
    path: 'notifications',
    name: routeNames.notifications.list,
    component: NotificationsList,
    meta: {
      noTopNav: true,
    },
    children: [

    ],
  },
];
