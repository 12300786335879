<template>
  <div class="asset-timeline-container">
    <InfiniteTimeline
      :items="items"
      :display-header="showHeader"
      :is-loading="isLoading"
      :has-more-data="hasMoreData"
      :expand-content-has-more-data="hasMoreAssetSpaceDataBeyondEvent"
      @moreData="getMoreData"
    />
  </div>
</template>
<script>
import InfiniteTimeline from '../../molecules/timeline/infinite-timeline';
import { constructTimelineItemsForAsset, constructLocationTimelineItemsForAsset } from '../../../helpers/asset-timeline-helper';
import { labelProvisioned } from '../../../constants';

export default {
  name: 'AssetTimeline',
  components: {
    InfiniteTimeline,
  },
  props: {
    assetId: { type: String, required: true },
    displayLocationTimeline: { type: Boolean, defaults: false },
  },
  computed: {
    hasMoreData() {
      return this.displayLocationTimeline
        ? this.hasMoreLocationData : this.hasMoreAssetInSpacesOrFacilitiesData;
    },
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.assetId);
    },
    assetInSpaces() {
      return this.$store.state.assets.assetInSpaces.assetsData
        .find((d) => d.assetId === this.assetId) || {};
    },
    assetInFacilities() {
      return this.$store.state.assets.assetInFacilities.assetsData
        .find((d) => d.assetId === this.assetId) || {};
    },
    items() {
      if (this.displayLocationTimeline) {
        return constructLocationTimelineItemsForAsset(
          this.asset,
          !this.isLoading && !this.hasMoreLocationData,
        );
      }

      const events = constructTimelineItemsForAsset(
        this.asset,
        this.assetInSpaces.items || [],
        this.assetInFacilities.items || [],
        !this.isLoading && !this.hasMoreAssetInSpacesOrFacilitiesData,
        this.$root.workspaceFeatures.spaceGroupingOnName,
        this.$root.workspaceFeatures.coalesceConsecutiveTimelineEvents,
      );

      if (this.$root.workspaceFeatures.shipmentsDemo && Array.isArray(this.asset.timeline)) {
        events.push(...this.asset.timeline);
      }

      return events
        .sort((a, b) => new Date(b.startTimestamp).getTime()
          - new Date(a.startTimestamp).getTime());
    },
    showHeader() {
      return this.items.filter((d) => d.title !== labelProvisioned).length > 0;
    },
    isLoadingAsset() { return this.$store.state.assets.isLoadingAsset; },
    isLoading() {
      return this.isAssetSpaceLoading
      || this.isAssetFacilityLoading
      || this.isLoadingAsset
      || this.$store.state.assets.isLoadingAssetLocations;
    },
    isAssetSpaceLoading() { return this.$store.state.assets.assetInSpaces.isLoading; },
    isAssetFacilityLoading() { return this.$store.state.assets.assetInFacilities.isLoading; },
    hasMoreAssetSpaceData() { return !!this.assetInSpaces.nextToken; },
    hasMoreAssetFacilityData() { return !!this.assetInFacilities.nextToken; },
    hasMoreAssetInSpacesOrFacilitiesData() {
      return this.hasMoreAssetSpaceData || this.hasMoreAssetFacilityData;
    },
    hasMoreLocationData() {
      return this.asset.locations.nextToken !== null;
    },
  },
  watch: {
    assetId() {
      this.getInitialData();
    },
    async isLoadingAsset() {
      await this.getInitialData();
    },
  },
  created() {
    this.getInitialData();
  },
  methods: {
    getInitialData() {
      if (!this.isLoadingAsset) {
        this.getAssetInFacilitiesData(true);
        this.getAssetInSpacesData(true);
      }
    },
    getAssetLocationData() {
      this.$store.dispatch('assets/getAssetLocations', { id: this.assetId, nfcId: this.asset.nfcId, nextToken: this.asset.locations.nextToken });
    },
    getAssetInSpacesData(reset = false) {
      this.$store.dispatch('assets/assetInSpaces/getAssetInSpaces', { assetId: this.assetId, reset });
    },
    getAssetInFacilitiesData(reset = false) {
      this.$store.dispatch('assets/assetInFacilities/getAssetInFacilities', { assetId: this.assetId, reset });
    },
    getMoreData() {
      if (this.displayLocationTimeline) {
        this.getAssetLocationData();
      } else if (this.$root.workspaceFeatures.assetSpaceHistoryTimeline) {
        if (!this.isAssetFacilityLoading && this.hasMoreAssetFacilityData) {
          this.getAssetInFacilitiesData();
        }
        if (!this.isAssetSpaceLoading && this.hasMoreAssetSpaceData) {
          this.getAssetInSpacesData();
        }
      }
    },
    hasMoreAssetSpaceDataBeyondEvent(timelineEvent) {
      // check whether there is more data beyond the last event
      if (this.hasMoreAssetSpaceData) {
        const assetInSpaces = this.assetInSpaces.items || [];

        if (assetInSpaces.length > 0) {
          const lastAssetInSpace = assetInSpaces[assetInSpaces.length - 1];

          // compare to timelineEvent
          const eventStartTimestamps = (timelineEvent.events || []).map((d) => d.startTimestamp);
          if (eventStartTimestamps.includes(lastAssetInSpace.enteredOn)) {
            return true;
          }
        }
      }

      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.asset-timeline-container {
  height: calc(100% - 35px);
}
</style>
