<template>
  <v-expansion-panels
    multiple
    flat
    tile
    class="expandable-timeline-item"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <TimelineItem
          v-bind="event"
          :hide-right-if-same="hideRightIfSame"
          :display-arrow="displayArrow"
          :hide-time-zone="hideTimeZone"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <component
          :is="expandContentComponent"
          v-if="Object.keys(expandContentComponent).length > 0"
          v-bind="expandContentProps"
          :items="event.events || []"
        />
        <div v-else>
          No data
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import TimelineItem from './timeline-item';

export default {
  components: {
    TimelineItem,
  },
  props: {
    event: { type: Object, required: true },
    hideRightIfSame: { type: Boolean, default: false },
    displayArrow: { type: Boolean, default: false },
    hideTimeZone: { type: Boolean, default: false },
    expandContentComponent: { type: Object, default: () => ({}) },
    expandContentProps: { type: Object, default: () => ({}) },
  },
};
</script>
<style lang="scss" scoped>
.expandable-timeline-item {
  .v-expansion-panel {
    background-color: transparent !important;
    &.v-expansion-panel--active {
      box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
      background-color: white;
      margin-bottom: 30px;
      .v-timeline-item .v-timeline-item__inner-dot .v-icon::before {
        display: none;
      }
    }
    .v-expansion-panel-header {
      padding: 0 !important;
      line-height: initial;
      padding-top: 10px !important;
      margin-bottom: 5px;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    .v-expansion-panel-content {
      margin-bottom: 10px;
    }
  }

}
</style>
<style lang="scss">
.expandable-timeline-item {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-expansion-panel {
    &.v-expansion-panel--active {
      .v-timeline-item:first-of-type .v-timeline-item__inner-dot .v-icon::before {
        display: none;
      }
    }
  }
  .v-expansion-panel-header {
    position: relative;
    .v-expansion-panel-header__icon{
      position: absolute;
      right: 0px;
    }
  }
}
</style>
