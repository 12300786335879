/* eslint-disable max-len */
import { workspaceFeatures } from '../../constants';

export default {
  computed: {
    workspaceFeatures() {
      return {
        assetSpaceHistoryTimeline: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.assetSpaceHistoryTimeline),
        assetLocationTimeline: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.assetLocationTimeline),
        spaceGroupingOnName: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.spaceGroupingOnName),
        coalesceConsecutiveTimelineEvents: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.coalesceConsecutiveTimelineEvents),
        gatewayTestEnabled: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.gatewayTestEnabled),
        demoMode: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.demoMode),
        shipmentsDemo: this.$store.getters['teams/enabledFeatures'].includes(workspaceFeatures.shipmentsDemo),
      };
    },
  },
};
