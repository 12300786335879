import ShipmentsList from '../views/shipments/ShipmentsList';
import ShipmentsMap from '../views/shipments/ShipmentsMap';
import ShipmentDetail from '../views/shipments/ShipmentDetail';
import { routeNames } from '../constants';

export default [
  {
    path: 'shipments',
    name: routeNames.shipments.list,
    component: ShipmentsList,
    children: [
      {
        path: 'map',
        name: routeNames.shipments.map,
        component: ShipmentsMap,
      },
      {
        path: ':id',
        name: routeNames.shipments.detail,
        component: ShipmentDetail,
        props: true,
      },
    ],
  },
];
