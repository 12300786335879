<template>
  <RouteWithChildren :parent-level="4">
    <ItemDetail
      ref="itemDetail"
      :item-title=" (space || {}).name || ''"
      :item="space"
      :rows="rowsGatewayDetail"
      :is-loading="isLoadingFacilitySpaces"
      :save-item="empty"
      :delete-item="empty"
      :delete-tracker="empty"
      :delete-item-dialogue-title="''"
      :delete-tracker-dialogue-title="''"
      :display-edit="false"
      :error="errorMessage"
      @close="close"
    >
      <template
        #explore-item-1
      >
        <GatewayStatusTimeline
          :space-id="spaceId"
          :facility-id="facilityId"
        />
      </template>
    </ItemDetail>
  </RouteWithChildren>
</template>
<script>
import ItemDetail from '../../components/organisms/items/item-detail';
import GatewayStatusTimeline from '../../components/organisms/facilities/gateway-status-timeline';
import RouteWithChildren from '../../components/organisms/layout/route-with-children';
import { routeNames, facilityTabSlugs } from '../../constants';

export default {
  name: 'FacilityGatewayDetail',
  components: {
    ItemDetail,
    GatewayStatusTimeline,
    RouteWithChildren,
  },
  props: {
    spaceId: { type: String, required: true },
    facilityId: { type: String, required: true },
  },
  computed: {
    isLoadingFacilitySpaces() {
      return this.$store.state.facilities.isLoadingFacilitySpaces;
    },
    rowsGatewayDetail() {
      const rows = [
        {
          name: 'Name',
          value: (this.space || {}).name,
        },
        {
          name: 'Short ID',
          value: (this.space || {}).shortId,
        },
        {
          name: 'Gateway ID',
          value: (this.space || {}).gatewayId,
        },
        {
          name: 'Status',
          value: (this.space || {}).status,
        },
      ];
      return rows;
    },
    space() {
      return ((this.$store.state.facilities.facilities
        .find(({ id }) => id === this.facilityId) || {}).spaces || [])
        .find((g) => g.spaceId === this.spaceId) || {};
    },
    errorMessage() {
      return this.$store.state.assets.errorMessage;
    },
    hasLoadedSpace() {
      return !!((this.$store.state.facilities.facilities
        .find(({ id }) => id === this.facilityId) || {}).spaces || [])
        .find((g) => g.spaceId === this.spaceId);
    },
  },
  async created() {
    if (!this.hasLoadedSpace) {
      await this.getFacilitySpaces(this.facilityId);
    }
  },
  methods: {
    empty() {},
    close() {
      this.$router.push(
        {
          name: routeNames.facilities.detail,
          query: { tab: facilityTabSlugs.gateways },
        },
      );
    },
    getFacilitySpaces() {
      this.$store.dispatch('facilities/getFacilitySpaces', this.facilityId);
    },
  },
};
</script>
