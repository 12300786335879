export const routeNames = {
  home: 'home',
  notFound: 'notFound',
  teamHome: 'teamHome',
  notifications: {
    list: 'notifications.list',
  },
  shipments: {
    list: 'shipments.list',
    detail: 'shipments.detail',
    map: 'shipments.map',
  },
  assets: {
    list: 'assets.list',
    detail: 'assets.detail',
    linkLabel: 'assets.linkLabel',
    linkAssetLabel: 'assets.linkAssetLabel',
    filters: 'assets.filters',
    add: 'assets.add',
    selectAsset: 'assets.selectAsset',
    map: 'assets.map',
    downloadTemperature: 'assets.downloadTemperature',
    downloadTemperatureRequireApp: 'downloadTemperatureRequireApp',
  },
  zones: {
    list: 'zones.list',
    detail: 'zones.detail',
    linkGateway: 'zones.linkGateway',
    linkZoneGateway: 'zones.linkZoneGateway',
    add: 'zones.add',
    selectZone: 'zones.selectZone',
    map: 'zones.map',
  },
  team: {
    addMember: 'team.addMember',
    list: 'team.list',
    detail: 'team.detail',
    memberDetail: 'team.memberDetail',
    teamsListRoute: 'team.teamsListRoute',
  },
  dashboards: {
    index: 'dashboards.index',
    detail: 'dashboards.detail',
  },
  facilities: {
    index: 'facilities.index',
    detail: 'facilities.detail',
    gatewayDetail: 'facilities.gatewayDetail',
    printerDetail: 'facilities.printerDetail',
    assetDetail: 'facilities.assetDetail',
    printJobAssetDetail: 'facilities.printJobAssetDetail',
  },
  nbIot: {
    detail: 'nbIot.detail',
    link: 'nbIot.link',
  },
  gateway: {
    status: 'gateway.status',
  },
  settings: 'settings',
};

export const table = {
  selectColumnKey: 'selectColumnKey',
};

export const userRoles = {
  viewer: 'Viewer',
  member: 'Member',
  admin: 'Admin',
  owner: 'Owner',
};

export const inputDataTypes = {
  number: 'Number',
  text: 'Text',
  date: 'Date',
  boolean: 'Boolean',
  select: 'Select',
};

export const cookieKeys = {
  teamId: 'teamId',
};

export const linkTrackerStatus = {
  noAccess: 'noAccess',
  alreadyLinked: 'alreadyLinked',
  availableForLinking: 'availableForLinking',
  unknownIssue: 'unknownIssue',
};

export const pollingDataInterval = 10000; // 10 secs

export const tLabelDiscoveryEnum = {
  bluetoothOff: 'BLUETOOTH_OFF',
  bluetoothUnsupported: 'BLUETOOTH_UNSUPPORTED',
  bluetoothUnauthorized: 'BLUETOOTH_UNAUTHORIZED',
  error: 'ERROR',
};

export const tLabelDataDownloadEnum = {
  error: 'ERROR',
  timeout: 'TIMEOUT',
  discovering: 'DISCOVERING',
  connecting: 'CONNECTING',
  discoveringServices: 'DISCOVERING_SERVICES',
  discoveringCharacteristics: 'DISCOVERING_CHARACTERISTICS',
  downloading: 'DOWNLOADING',
  complete: 'COMPLETE',
};

export const workspaceFeatures = {
  assetSpaceHistoryTimeline: 'AssetSpaceHistoryTimeline',
  assetLocationTimeline: 'AssetLocationTimeline',
  spaceGroupingOnName: 'SpaceGroupingOnName',
  coalesceConsecutiveTimelineEvents: 'CoalesceConsecutiveTimelineEvents',
  gatewayTestEnabled: 'GatewayTestEnabled',
  demoMode: 'DemoMode',
  shipmentsDemo: 'ShipmentsDemo',
};

export const errorMessages = {
  noPermission: 'User does not have necessary permission to carry-out action',
};

export const expoBackOffTimes = [200, 500, 1000, 3000];

export const pollingNbIotDataInterval = Number(process.env.VUE_APP_NB_IOT_POLLING_MS);
export const pollingNbIotDataMaxDuration = Number(process.env.VUE_APP_NB_IOT_MAX_POLL_DURATION);

export const pollingGatewayStatusDataInterval = Number(
  process.env.VUE_APP_GATEAY_STATUS_POLLING_MS,
);
export const pollingGatewayStatusMaxDuration = Number(
  process.env.VUE_APP_GATEAY_STATUS_MAX_POLL_DURATION,
);

export const facilityTabSlugs = {
  printers: 'printers',
  gateways: 'gateways',
  assets: 'assets',
};

export const itemDetailTabSlugs = {
  timeline: 'timeline',
  map: 'map',
  temperature: 'temperature',
  assets: 'assets',
};

export const mapItemTypes = {
  destination: 'destination',
  alert: 'alert',
};

export const mapEntityTypes = {
  asset: 'asset',
  facility: 'facility',
  zone: 'zone',
};

export const labelProvisioned = 'Label activated';

export const nbIotNoLocationDisplayName = 'Unknown';

export const locationStates = {
  inMotion: 'IN_MOTION',
  inMotionStationaryOverlap: 'IN_MOTION_STATIONARY_OVERLAP', // in motion, overlaps with stationary
  stationary: 'STATIONARY',
};

export const locationStateDisplayNames = {
  [locationStates.inMotion]: 'In motion',
  [locationStates.inMotionStationaryOverlap]: 'In motion',
  [locationStates.stationary]: 'Stationary',
};

export const onlineStates = {
  online: 'Online',
  offline: 'Offline',
};

/** ASSET FILTERS */
export const assetFilterKeys = {
  consignments: 'consignments',
  facilities: 'facilities',
  zones: 'zones',
};

export const assetFilterSelectedKeys = {
  consignments: 'selectedConsignmentId',
  facilities: 'selectedFacilityId',
  zones: 'selectedZoneId',
};

export const filterKeyToQueryStringParam = {
  [assetFilterKeys.consignments]: 'inConsignments',
  [assetFilterKeys.facilities]: 'inFacilities',
  [assetFilterKeys.zones]: 'inZones',
};

export const sortOrder = {
  firstSeenAsc: 'FirstSeenAsc',
};

export const justNow = 'Just now';

export function convertCelsiusToFahrenheit(celsius) {
  return Math.round((celsius * 9) / 5 + 32); // no decimal places
}

export const temperatureUnits = {
  celsius: '°C',
  fahrenheit: '°F',
};

export const icons = {
  space: 'mdi-router-wireless',
  // space: 'mdi-select-group',
  facility: 'mdi-warehouse',
  truck: 'mdi-truck',
};

export const alertCategories = {
  temperature: 'temperature',
  consignment: 'consignment',
};

export const alertTypes = {
  belowTempThreshold: 'belowTempThreshold',
  aboveTempThreshold: 'aboveTempThreshold',
  separatedFromConsignment: 'separatedFromConsignment',
  unitedWithConsignment: 'unitedWithConsignment',
};

export const alertIcons = {
  [alertCategories.temperature]: {
    [alertTypes.belowTempThreshold]: 'mdi-thermometer-minus',
    [alertTypes.aboveTempThreshold]: 'mdi-thermometer-plus',
  },
  [alertCategories.consignment]: {
    [alertTypes.separatedFromConsignment]: 'mdi-call-split',
    [alertTypes.unitedWithConsignment]: 'mdi-call-merge',
  },
};

export const alertDisplayNames = {
  [alertCategories.temperature]: {
    [alertTypes.belowTempThreshold]: 'Below temperature threshold',
    [alertTypes.aboveTempThreshold]: 'Above temperature threshold',
  },
  [alertCategories.consignment]: {
    [alertTypes.separatedFromConsignment]: 'Separated from consignment',
    [alertTypes.unitedWithConsignment]: 'United with consignment',
  },
};

export const createDeepCopy = (x) => (JSON.parse(JSON.stringify(x)));
