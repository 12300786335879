<template>
  <div
    v-if="items.length === 0"
    class="no-data-container"
  >
    No floor plans
  </div>
  <div
    v-else
    ref="floorPlansTableContainer"
    class="floor-plans-table-conatainer"
  >
    <div
      :style="{
        'height': floorPlansTableHeight + 'px',
        'overflow-y': 'auto'
      }"
    >
      <v-card>
        <v-data-table
          :headers="floorPlanHeaders"
          :loading="isLoading"
          :items="items"
          :items-per-page="-1"
          class="table"
          hide-default-footer
        >
          <template #body>
            <tbody>
              <!-- Hack to force v-data-table to recognise responsive loading prop-->
              <tr v-if="isLoading">
                <td>
                  <v-progress-linear
                    :value="1"
                    absolute
                    height="0"
                  />
                </td>
              </tr>
              <tr
                v-for="(item, i) in items"
                :key="i"
              >
                <td
                  v-for="(col, j) in floorPlanHeaders"
                  :key="j"
                  class="text-left"
                >
                  <span v-if="col.isImage">
                    <div class="image-container">
                      <v-img
                        :ref="`image-${i}`"
                        width="200"
                        contain
                        cross-origin
                        :src="item.imageUrl"
                        @click="() => toggleImageOverlay(i, item, col)"
                      >
                        <v-progress-circular
                          v-if="loadingImages.includes(i)"
                          class="loader"
                          color="primary"
                          indeterminate
                          absolute
                          size="30"
                        />
                      </v-img>
                      <v-overlay
                        v-if="displayImageOverlays.includes(i)"
                        color="#036358"
                        @click="() => toggleImageOverlay(i)"
                      >
                        <v-img
                          contain
                          max-width="100vw"
                          max-height="100vh"
                          :src="overlaySrc"
                        />
                      </v-overlay>
                    </div>
                  </span>
                  <span
                    v-else
                    if
                    col.value="name"
                  > {{ item.name }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <resize-observer @notify="setFloorPlansTableHeight" />
  </div>
</template>
<script>
// to be used for unique name attribue;
export default {
  name: 'ReelablesFloorPlanTable',
  props: {
    id: { type: String, required: true },
    items: { type: Array, default: () => ([]) },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      loadingImages: [],
      displayImageOverlays: [],
      overlaySrc: null,
      floorPlanHeaders: [
        { text: 'Name', value: 'name' },
        {
          text: 'Image',
          isImage: true,
        },
      ],
      floorPlansTableHeight: 300,
    };
  },
  computed: {
    facility() {
      return this.$store.state.facilities.facilities.find(({ id }) => id === this.id);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setFloorPlansTableHeight();
    });
  },
  methods: {
    async toggleImageOverlay(index, item) {
      if (!this.loadingImages.includes(index)) {
        if (this.displayImageOverlays.includes(index)) {
          this.displayImageOverlays = this.displayImageOverlays.filter((i) => i !== index);
        } else {
          this.loadingImages.push(index);
          const floorPlanId = this.facility.floorPlans
            .items.find((fp) => fp.id === item.id).id;
          this.getFacilityFloorPlan(floorPlanId);
          this.overlaySrc = this.facility.floorPlans;
          this.displayImageOverlays.push(index);
          this.overlaySrc = item.imageUrl;
        }
        this.loadingImages = this.loadingImages.filter((i) => i !== index);
      }
    },
    getFacilityFloorPlan(floorPlanId) {
      this.$store.dispatch('facilities/getFacilityFloorPlan', { facilityId: this.id, floorPlanId });
    },
    setFloorPlansTableHeight() {
      this.floorPlansTableHeight = (this.$refs.floorPlansTableContainer || {}).clientHeight;
    },
  },
};
</script>
<style scoped lang="scss">
.no-data-container {
  margin-top: 40px;
}
.floor-plans-table-conatainer {
  height: 100%;
}
td {
  min-width: 110px;
  &.text-left {
    text-align: left;
  }
}
.image-container {
  position: relative;
  // z-index: 10;
  .loader {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

<style lang="scss">
.table tbody {
  display: table-row-group !important;
}
</style>
