<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    :row-is-clickable="false"
    title="Notifications"
    @clickRow="clickRow"
  />
</template>
<script>
import { routeNames, alertIcons, alertDisplayNames } from '../../../constants';
import ItemsTable from '../table/items-table';
import { shipments } from '../../../data/shipments-demo';
import { formatDate } from '../../../mixins/date-time-helper';
import { constructDate, constructTime } from '../../../helpers/date-formatting-helper';

export default {
  name: 'NotificationsTable',
  components: {
    ItemsTable,
  },
  props: {
    selectedId: { type: String, default: null },
    itemIds: { type: Array, default: () => [] },
  },
  computed: {
    hasMoreData() { return false; },
    isLoading() { return false; },
    headers() {
      const headers = [
        { name: 'Timestamp', key: 'timestamp' },
        { name: 'When', key: 'when' },
        { name: 'Type', key: 'type' },
        { name: 'Detail', key: 'detail' },
        { name: '', key: 'action' },
      ];

      return headers;
    },
    rows() {
      const rows = shipments
        .filter((s) => (s.alerts || []).length > 0)
        .flatMap((s) => s.alerts.map((a) => {
          const date = new Date(a.timestamp);
          return {
            timestamp: `${constructDate(date, false)}, ${constructTime(date)}`,
            when: formatDate(new Date(a.timestamp)),
            timestampIso: a.timestamp,
            type: {
              icon: alertIcons[a.category][a.type],
              value: alertDisplayNames[a.category][a.type],
              iconColor: 'error',
              iconSize: '20',
            },
            detail: a.message,
            action: {
              buttons: [{
                props: {
                  text: 'View Shipment',
                  isText: true,
                  isSmall: true,
                  onClick: () => this.$router.push({
                    name: routeNames.shipments.detail,
                    params: { id: s.id },
                  }),
                },
              }],
            },
          };
        })).sort((a, b) => b.timestampIso - a.timestampIso);

      return rows;
    },
  },
  methods: {
    clickRow(row) {
      console.log('clickRow', row);
      // this.$router.push({
      //   name: routeNames.shipments.detail,
      //   params: {
      //     id: row.id,
      //   },
      // }).catch(() => { });
    },
    getData() {
      // get data
    },
  },
};
</script>
