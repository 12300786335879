import api from '../../api';

const getDefaultState = () => ({
  isFakeData: false,
  isLoading: false,
  errorMessage: '',
  assetsData: [], // [{ assetId: assetId1 items: [...], nextToken}, assetId: assetId2: ... }]
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      if (state.isFakeData) return;
      Object.assign(state, getDefaultState());
    },
    SET_FAKE_DATA(state, assetsData = []) {
      state.isFakeData = true;
      state.assetsData = assetsData;
    },
    START_LOADING(state) {
      state.isLoading = true;
    },
    STOP_LOADING(state) {
      state.isLoading = false;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      if (state.isFakeData) return;
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    SET_ASSETS_DATA(state, {
      assetId, items, nextToken, reset,
    }) {
      if (state.isFakeData) return;
      if (reset) {
        state.assetsData = state.assetsData.filter((d) => d.assetId !== assetId);
      }

      const existingData = state.assetsData.find((d) => d.assetId === assetId);
      if (existingData) {
        existingData.items = [...(existingData.items || []), ...items];
        existingData.nextToken = nextToken;
      } else {
        state.assetsData.push({
          assetId, items, nextToken,
        });
      }
    },
  },
  actions: {
    async getAssetInFacilities(context, { assetId, reset }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING');
      try {
        const existingData = context.state.assetsData.find((d) => d.assetId === assetId);
        if (reset || !existingData || existingData.nextToken !== null) {
          const data = await api.getAssetFacilityHistory(
            assetId,
            reset ? null : (existingData || {}).nextToken,
          );

          context.commit('SET_ASSETS_DATA', {
            ...data, assetId, reset,
          });
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
  },
};
