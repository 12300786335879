<template>
  <Map
    :items="locations"
    :includes-first-point="!hasMoreLocations"
    :destination="destination"
    :alerts="alerts"
    @clickedClusteredItems="(items) => locationClicked(items[0])"
    @clickedUnclusteredItem="locationClicked"
  >
    <div slot="popup-content">
      <LastSeenMapPopup
        v-if="isLastSeenPopup"
        :last-seen-time="lastSeenTime"
        :timer-id="timer"
      />
      <MapPopup
        v-else
        :data="popUpData"
      />
    </div>
  </Map>
</template>
<script>
import Map from '../../atoms/map/map-box';
import LastSeenMapPopup from '../../atoms/map/last-seen-map-popup';
import MapPopup from '../../atoms/map/map-popup';

export default {
  components: {
    Map,
    LastSeenMapPopup,
    MapPopup,
  },
  props: {
    locations: { type: Array, default: () => [] },
    timer: { type: Number, default: 0 },
    hasMoreLocations: { type: Boolean, default: false },
    destination: { type: Object, default: null },
    alerts: { type: Array, default: () => [] },
  },
  data() {
    return {
      isLastSeenPopup: false,
      lastSeenTime: null,
      popUpData: null,
    };
  },
  methods: {
    locationClicked(locationProperties) {
      console.log('locationProperties', locationProperties);
      if (locationProperties.type) {
        console.log('Here');
        this.isLastSeenPopup = false;
        this.lastSeenTime = locationProperties.timestamp;
        this.popUpData = locationProperties;
      } else {
        this.isLastSeenPopup = true;
        this.lastSeenTime = locationProperties.timestamp;
      }
    },
  },
};
</script>
