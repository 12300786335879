<template>
  <Dialogue
    :is-visible="isVisible"
    is-wide
  >
    <fragment>
      <p class="is-bold text-center">
        {{ title }}
      </p>
      <slot name="map-dialogue-content" />
      <ButtonsContainer class="buttons-container">
        <Button
          text="Close"
          :on-click="() => $emit('close')"
        />
      </ButtonsContainer>
    </fragment>
  </dialogue>
</template>
<script>
import Dialogue from '../../atoms/dialogue/dialogue';
import Button from '../../atoms/button/button';
import ButtonsContainer from '../layout/buttons-container';

export default {
  name: 'MapClusterListDialogue',
  components: {
    Dialogue,
    Button,
    ButtonsContainer,
  },
  props: {
    isVisible: { type: Boolean, default: false },
    title: { type: String, default: null },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  margin-top: 20px;
}
</style>
