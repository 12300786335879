<template>
  <fragment>
    <ExpandableTimelineItem
      v-if="hasChildEvents"
      :event="event"
      :hide-right-if-same="hideRightIfSame"
      :display-arrow="displayArrow"
      :hide-time-zone="hideTimeZone"
      :is-expand-content="isExpandContent"
      :expand-content-component="expandContentComponent"
      :expand-content-props="{
        hideTimeZone: true,
        hasMoreData: expandContentHasMoreData(event),
        isExpandContent: true,
        isLoading: isLoading,
      }"
    />
    <TimelineItem
      v-else
      v-bind="event"
      :hide-right-if-same="hideRightIfSame"
      :display-arrow="displayArrow"
      :hide-time-zone="hideTimeZone"
    />
  </fragment>
</template>
<script>
import TimelineItem from '../../atoms/timeline/timeline-item';
import ExpandableTimelineItem from '../../atoms/timeline/expandable-timeline-item';
import InfiniteTimeline from './infinite-timeline';

export default {
  components: {
    TimelineItem,
    ExpandableTimelineItem,
  },
  props: {
    event: { type: Object, required: true },
    hideRightIfSame: { type: Boolean, default: false },
    displayArrow: { type: Boolean, default: false },
    hideTimeZone: { type: Boolean, default: false },
    isExpandContent: { type: Boolean, default: false },
    expandContentHasMoreData: { type: Function, default: () => false },
    isLoading: { type: Boolean, default: false },
  },
  computed: {
    hasChildEvents() { return Array.isArray(this.event.events) && this.event.events.length > 0; },
    expandContentComponent() { return InfiniteTimeline; },
  },
};
</script>
<style lang="scss">
@import '@/styles/_breakpoints.scss';
.timeline-container .v-expansion-panel-header{
  font-size: 0.75rem !important;
  @include mq($from: md) {
    font-size: 0.875rem !important;
  }
}
</style>
