/* eslint-disable import/prefer-default-export */

import { createDeepCopy, icons, labelProvisioned } from '../constants';
import { groupAssetInSpacesOnSpaceName } from './space-name-grouping-helper';
import { constructDate, constructTime, getBrowserTimeZone } from './date-formatting-helper';

import colours from '../styles/_updated-variables.scss';
import constructDisplayAddress from './nb-iot-current-location-helper';

const BROWSER_TIME_ZONE = getBrowserTimeZone();

export function constructEvent(
  isoDate,
  title,
  text,
  colour,
  isoDate2 = null,
  timeZone = BROWSER_TIME_ZONE,
  isStart = false,
  icon = null,
  events = null,
  link = null,
) {
  const date = new Date(isoDate);

  const event = {
    startTimestamp: date.toISOString(),
    startDate: timeZone ? constructDate(date, false, timeZone) : constructDate(date),
    startTime: timeZone ? constructTime(date, timeZone) : constructTime(date),
    title,
    text,
    color: colour,
    timeZone,
    isStart,
    icon,
    events,
    link,
  };

  if (isoDate2) {
    const date2 = new Date(isoDate2);
    event.endTimestamp = date2.toISOString();
    event.endDate = timeZone ? constructDate(date2, false, timeZone) : constructDate(date2);
    event.endTime = timeZone ? constructTime(date2, timeZone) : constructTime(date2);
  }

  return event;
}

const eventTypes = {
  assetSpace: 'assetSpace',
  assetFacility: 'assetFacility',
};

function constructEventTitle(
  enterExitSpell,
  spaceGroupingOnName,
) {
  if (enterExitSpell.eventType === eventTypes.assetFacility) {
    return enterExitSpell.facilityName;
  }

  if (spaceGroupingOnName) {
    return enterExitSpell.groupName;
  }

  return enterExitSpell.spaceName;
}

function eventTypeToIcon(eventType) {
  if (eventType === eventTypes.assetSpace) {
    return icons.space;
  }

  if (eventType === eventTypes.assetFacility) {
    return icons.facility;
  }

  return null;
}

// Combine any consecutive spells that are same space / group
function coalesceSpells(spells, spaceGroupingOnName) {
  const coalescedEvents = [];

  spells
    .forEach((spell) => {
      const prevSpell = coalescedEvents.length > 0
        ? coalescedEvents[coalescedEvents.length - 1]
        : null;

      const sameType = prevSpell && prevSpell.eventType === spell.eventType;
      const spellName = constructEventTitle(spell, spaceGroupingOnName);
      const prevName = prevSpell ? constructEventTitle(prevSpell, spaceGroupingOnName) : null;

      if (Array.isArray(spell.spaceEpisodes)) {
        // eslint-disable-next-line no-param-reassign
        spell.spaceEpisodes = coalesceSpells(spell.spaceEpisodes, spaceGroupingOnName);
      }

      if (!sameType || spellName !== prevName) {
        coalescedEvents.push({
          ...spell,
          spaceEpisodes: Array.isArray(spell.spaceEpisodes)
            ? coalesceSpells(spell.spaceEpisodes, spaceGroupingOnName)
            : null,
        });
      } else {
        // combine the time periods
        prevSpell.enteredOn = spell.enteredOn;
        prevSpell.spaceEpisodes = Array.isArray(prevSpell.spaceEpisodes)
          ? coalesceSpells([
            ...prevSpell.spaceEpisodes,
            ...spell.spaceEpisodes], spaceGroupingOnName)
          : null;
      }
    });

  return coalescedEvents;
}

function constructSpaceOrFacilityEvent(episode, spaceGroupingOnName) {
  return constructEvent(
    episode.enteredOn,
    constructEventTitle(episode, spaceGroupingOnName),
    episode.spaceNames ? episode.spaceNames.join(', ') : '',
    episode.hasExited ? colours.gray : colours.active,
    episode.hasExited ? episode.exitedOn : null,
    episode.facilityTimeZone || BROWSER_TIME_ZONE,
    false,
    eventTypeToIcon(episode.eventType),
    Array.isArray(episode.spaceEpisodes)
      ? episode.spaceEpisodes.map((e) => constructSpaceOrFacilityEvent(e, spaceGroupingOnName))
      : null,
  );
}

export function constructTimelineItemsForAsset(
  asset,
  assetSpaceEpisodes = [],
  assetFacilityEpisodes = [],
  includeLabelProvision = false,
  spaceGroupingOnName = false,
  coalesceEnterExitSpells = false,
) {
  const anyAssetFacilityEpisodes = assetFacilityEpisodes.length > 0;

  const shouldGroupOnSpaceName = !anyAssetFacilityEpisodes && spaceGroupingOnName;

  const allEpisodes = [
    ...assetSpaceEpisodes.map((episode) => ({
      ...episode,
      eventType: eventTypes.assetSpace,
    })),
    ...assetFacilityEpisodes.map((episode) => ({
      ...episode,
      eventType: eventTypes.assetFacility,
    })),
  ];

  // sort by enteredOn descending
  allEpisodes.sort((a, b) => new Date(b.enteredOn) - new Date(a.enteredOn));

  // group assetSpace episodes into assetFacility episodes
  // - assume asset-facility episodes are not overlapping
  const groupedEpisodes = [];
  allEpisodes.forEach((episode) => {
    if (episode.eventType === eventTypes.assetFacility) {
      // check whether this facility episode is already in the list
      const existingFacilityEpisode = groupedEpisodes
        .find((ge) => ge.facilityId && ge.facilityId === episode.facilityId
        && ge.enteredOn === episode.enteredOn);

      if (!existingFacilityEpisode) {
        groupedEpisodes.push({
          ...episode,
          spaceEpisodes: [],
        });
      }
    } else if (episode.eventType === eventTypes.assetSpace) {
      const facilityEpisode = allEpisodes.find(
        (fe) => fe.eventType === eventTypes.assetFacility
          && fe.facilityId === episode.facilityId
          // overlapping facility episode
          && ((!fe.exitedOn && (!episode.exitedOn || episode.exitedOn >= fe.enteredOn))
          || (fe.exitedOn
            && ((!episode.exitedOn && episode.enteredOn <= fe.exitedOn)
              // eslint-disable-next-line max-len
              || (episode.exitedOn && episode.enteredOn <= fe.exitedOn && episode.exitedOn >= fe.enteredOn)
            ))),
      );
      if (facilityEpisode) {
        const existingFacilityEpisode = groupedEpisodes
          .find((ge) => ge.facilityId && ge.facilityId === facilityEpisode.facilityId
            && ge.enteredOn === facilityEpisode.enteredOn);

        if (existingFacilityEpisode) {
          existingFacilityEpisode.spaceEpisodes.push(episode);
        } else {
          groupedEpisodes.push({
            ...facilityEpisode,
            spaceEpisodes: [episode],
          });
        }
      } else {
        // no facility episode found, add as is -> no facility grouping
        groupedEpisodes.push(episode);
      }
    }
  });

  let enterExitSpells = createDeepCopy(
    shouldGroupOnSpaceName
      ? groupAssetInSpacesOnSpaceName(createDeepCopy(groupedEpisodes))
      : groupedEpisodes,
  );

  if (coalesceEnterExitSpells) {
    enterExitSpells = coalesceSpells(enterExitSpells, shouldGroupOnSpaceName);
  }

  const events = [];

  enterExitSpells.forEach((enterExitSpell) => {
    events.push(constructSpaceOrFacilityEvent(enterExitSpell, shouldGroupOnSpaceName));
  });

  if (includeLabelProvision && asset.labelAddedOn) {
    events.push(constructEvent(
      asset.labelAddedOn,
      'Label provisioned',
      `Label linked to ${asset.name}`,
      colours.start,
    ));
  }

  return events;
}

export function constructLocationTimelineItemsForAsset(
  asset,
  includeLabelProvision = false,
  includeState = false,
) {
  const dedupedLocations = ((asset.locations || {}).items || [])
    // ensure locations are sorted by timestamp in ascending order
    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    .filter((loc) => loc.address)
    .map((loc) => {
      const displayName = constructDisplayAddress(loc.address, true);
      if (!displayName) {
        return null;
      }
      return {
        ...loc,
        displayName,
        name: displayName.name,
        subTitle: displayName.subTitle,
        timestamp: loc.timestamp,
        lastUpdatedAt: loc.lastUpdatedAt || loc.timestamp,
      };
    })
    .filter((loc) => loc)
    // de-dupe locations based on name
    .reduce((acc, loc) => {
      const last = acc[acc.length - 1];
      if (last && last.name === loc.name) {
        last.lastUpdatedAt = loc.lastUpdatedAt;
      } else {
        acc.push({
          ...loc,
          stillAtLocation: true,
        });
        if (last) {
          last.stillAtLocation = false;
        }
      }
      return acc;
    }, [])
    // sort by timestamp in descending order again
    .reverse();

  const events = dedupedLocations.map((loc) => constructEvent(
    loc.timestamp,
    loc.name,
    includeState ? loc.subTitle : '',
    loc.stillAtLocation ? colours.active : colours.gray,
    loc.lastUpdatedAt,
  ));

  if (includeLabelProvision && asset.labelAddedOn) {
    events.push(constructEvent(
      asset.labelAddedOn,
      labelProvisioned,
      `Linked to ${asset.name}`,
      colours.start,
      undefined,
      undefined,
      true,
    ));
  }

  return events;
}
