/* eslint-disable no-unused-vars */
import {
  icons, alertIcons, routeNames, itemDetailTabSlugs,
  createDeepCopy,
} from '../constants';
import { constructEvent } from '../helpers/asset-timeline-helper';
import colours from '../styles/_updated-variables.scss';
import {
  shipment1Locations as locs1,
  shipment2Locations as locs2,
  shipment3Locations as locs3,
  shipment4Locations as locs4,
} from './locations';
import { constructTemperatures } from './temperature-helper';

const NOW_MS = Date.now();
const MIN_AS_MS = 60 * 1000;
const HOUR_AS_MS = 60 * MIN_AS_MS;
const DAY_AS_MS = 24 * HOUR_AS_MS;

const [
  shipment1Activation,
  shipment2Activation,
  shipment3Activation,
  shipment4Activation,
  shipment5Activation, // on same truck as shipment2
] = [
  new Date(NOW_MS - (2 * DAY_AS_MS - 0.25 * HOUR_AS_MS)),
  new Date(NOW_MS - (3 * HOUR_AS_MS)),
  new Date(NOW_MS - (4 * DAY_AS_MS + 3 * HOUR_AS_MS)),
  new Date(NOW_MS - (6 * HOUR_AS_MS)),
  new Date(NOW_MS - (3 * HOUR_AS_MS)),
];

const [
  shipment1LastSeen,
  shipment2LastSeen,
  shipment3LastSeen,
  shipment4LastSeen,
  shipment5LastSeen, // on same truck as shipment2
] = [
  new Date(),
  new Date(NOW_MS - 4 * MIN_AS_MS),
  new Date(NOW_MS - 3 * MIN_AS_MS),
  new Date(NOW_MS - 2 * MIN_AS_MS),
  new Date(NOW_MS - 4 * MIN_AS_MS),
];

const [
  shipment1Departure,
  shipment2Departure,
  shipment3Departure,
  shipment4Departure,
  shipment5Departure, // on same truck as shipment2
] = [
  new Date(NOW_MS - (2 * DAY_AS_MS - 3 * HOUR_AS_MS)),
  new Date(NOW_MS - (1.3 * HOUR_AS_MS)),
  new Date(NOW_MS - (1 * DAY_AS_MS + 0.25 * HOUR_AS_MS)),
  new Date(NOW_MS - (0.75 * HOUR_AS_MS)),
  new Date(NOW_MS - (1.3 * HOUR_AS_MS)),
];

const [
  shipment1JourneyTimeMs,
  shipment2JourneyTimeMs,
  shipment3JourneyTimeMs,
  shipment4JourneyTimeMs,
  shipment5JourneyTimeMs, // on same truck as shipment2
] = [
  shipment1LastSeen - new Date(shipment1Departure),
  shipment2LastSeen - new Date(shipment2Departure),
  shipment3LastSeen - new Date(shipment3Departure),
  shipment4LastSeen - new Date(shipment4Departure),
  shipment5LastSeen - new Date(shipment5Departure),
];

const shipment1Locations = locs1.map((loc, i) => ({
  ...loc,
  timestamp: new Date(shipment1Departure.getTime()
    + ((locs1.length - i) * (shipment1JourneyTimeMs / locs1.length))).toISOString(),
}));

const shipment2Locations = locs2.map((loc, i) => ({
  ...loc,
  timestamp: new Date(shipment2Departure.getTime()
    + ((locs2.length - i) * (shipment2JourneyTimeMs / locs2.length))).toISOString(),
}));

const shipment3AlertStartMs = NOW_MS - (5 * HOUR_AS_MS);

const shipment3Locations = locs3.map((loc, i) => {
  const delta = (locs3.length - i) * (shipment3JourneyTimeMs / locs3.length);

  const deltaPlus = (locs3.length - i + 1) * (shipment3JourneyTimeMs / locs3.length);
  const deltaMinus = (locs3.length - i - 1) * (shipment3JourneyTimeMs / locs3.length);

  const alertTime = shipment3AlertStartMs - shipment3Departure.getTime();

  let isAlert = false;
  if (delta >= alertTime
    && deltaPlus > alertTime
    && deltaMinus < alertTime
  ) {
    isAlert = true;
  }

  return {
    ...loc,
    timestamp: isAlert
      ? new Date(shipment3AlertStartMs).toISOString() // used to match the alert in Shipment Detail
      : new Date(shipment3Departure.getTime() + delta).toISOString(),
    isAlert,
  };
});

const shipment4AlertStartMs = NOW_MS - (0.15 * HOUR_AS_MS);

const shipment4Locations = locs4.map((loc, i) => {
  const delta = (locs4.length - i) * (shipment4JourneyTimeMs / locs4.length);

  const deltaPlus = (locs4.length - i + 1) * (shipment4JourneyTimeMs / locs4.length);
  const deltaMinus = (locs4.length - i - 1) * (shipment4JourneyTimeMs / locs4.length);

  const alertTime = shipment4AlertStartMs - shipment4Departure.getTime();

  let isAlert = false;
  if (delta >= alertTime
    && deltaPlus > alertTime
    && deltaMinus < alertTime
  ) {
    isAlert = true;
  }

  return {
    ...loc,
    timestamp: isAlert
      ? new Date(shipment4AlertStartMs).toISOString() // used to match the alert in Shipment Detail
      : new Date(shipment4Departure.getTime() + delta).toISOString(),
    isAlert,
  };
});

const shipment5Locations = createDeepCopy(shipment2Locations);

const [
  asset1Id,
  asset2Id,
  asset3Id,
  asset4Id,
  asset5Id,
  asset6Id,
  asset7Id,
  asset8Id,
  asset9Id,
  asset10Id,
  asset11Id,
  // add another 12 assets
  asset12Id,
  asset13Id,
  asset14Id,
  asset15Id,
  asset16Id,
  asset17Id,
  asset18Id,
  asset19Id,
  asset20Id,
  asset21Id,
  asset22Id,
  asset23Id,
] = [
  'LZhAVXEzRjrQ',
  'xDc-7Q5J0lW5',
  'x8r1rpzy-t1w',
  's54zaetcl9jq',
  '2hdf79vvcqe9',
  'prd3k5awlqfn',
  'j0vaw59x8b-h',
  'aczia8totltb',
  'c9s8g99s2qfd',
  'ceaafvbnongw',
  'c_3essbj1ita',
  '8u70duifjsru',
  'n_7gs9t1bt_y',
  'xk9d6opv8qeo',
  'md79a4zr7yxa',
  'b_ehk2tjcqgb',
  'moyvcejopazz',
  '14x4dk-wt4ga',
  'e5it68t80le8',
  'jdsjdw_7xm3f',
  'di8timwzz825',
  'd1lq_6fypbpg',
  'nwvruv63ur3_',
];

const [
  facility1Id,
  facility2Id,
  facility3Id,
  facility4Id,
] = [
  '80116606-3717-4122-b56c-76522f12b244',
  '45332001-561d-4cae-a104-ab98bbfcc958',
  '40879a3d-f542-4fc2-aa5c-762f4516bcf2',
  '6fbd8a3c-e9e8-47a2-98e6-38eaca1b473a',
];

const [
  shipment1Id,
  shipment2Id,
  shipment3Id,
  shipment4Id,
  shipment5Id,
] = [
  '476f3511-277f-4382-8791-bbc14df3af15',
  '0c01b068-5013-4c42-8a11-b810d1c5ad46',
  '5dd95ec8-3409-4161-b1e1-aa415a87ed30',
  '85049ce0-fa56-4d44-9de5-5d200d4e0c92',
  '6dd95ec8-3409-4161-b1e1-aa415a87ab31',
];

const [
  shipment1Name,
  shipment2Name,
  shipment3Name,
  shipment4Name,
  shipment5Name,
] = [
  'D765432',
  'S987654',
  'D800091',
  'S983450',
  'S987657',
];

export const facilities = [
  {
    id: facility1Id,
    name: 'Ankaa - SDF',
    timeZone: 'America/Kentucky/Louisville',
    address: {
      road: '2801 Constant Comment Pl',
      city: 'Louisville',
      state: 'KY',
      postalCode: 'KY 40299',
      country: 'United States',
    },
    location: {
      latitude: 38.44593234372793,
      longitude: -85.49743339678032,
    },
  },
  {
    id: facility2Id,
    name: 'SG - Santa Fe',
    timeZone: 'America/Los_Angeles',
    address: {
      road: '13500 Foster Rd',
      city: 'Santa Fe Springs',
      state: 'CA',
      postalCode: '90670',
      country: 'United States',
    },
    location: {
      latitude: 33.91371966446207,
      longitude: -118.04395309934422,
    },
  },
  {
    id: facility3Id,
    name: 'TW&M - Glendale',
    timeZone: 'America/Los_Angeles',
    address: {
      line1: 'Lower Level Parking Garage',
      road: '221 N Glendale Ave',
      city: 'Glendale',
      state: 'CA',
      postalCode: '91206',
      country: 'United States',
    },
    location: {
      latitude: 34.15963021108207,
      longitude: -118.24524154929084,
    },
  },
  {
    id: facility4Id,
    name: 'TW&M - Northridge',
    timeZone: 'America/Los_Angeles',
    address: {
      road: '19336 Nordhoff St',
      city: 'Northridge',
      state: 'CA',
      postalCode: '91324',
      country: 'United States',
    },
    location: {
      latitude: 34.246929415246534,
      longitude: -118.55668544650288,
    },
  },
];

const [
  facility1,
  facility2,
  facility3,
  facility4,
] = facilities;

const [
  temperatureThreshold1,
  temperatureThreshold2,
  temperatureThreshold3,
] = [
  {
    id: '1',
    min: 10,
    max: 15,
    active: {
      from: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
      to: null,
    },
  },
  {
    id: '2',
    min: 13,
    max: 16,
    active: {
      from: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
      to: null,
    },
  },
  {
    id: '3',
    min: 7,
    max: 10,
    active: {
      from: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
      to: null,
    },
  },
];

export const assets = [
  // A01
  {
    // A01
    id: asset1Id,
    name: 'P00342',
    createdOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '0-2977',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
    nfcId: '7067065863c542c1',
    bleId: 1,
    locations: {
      items: shipment1Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment1Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment1Activation.getTime(),
        (shipment1LastSeen - shipment1Activation) / shipment1Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset1Id,
        spaceId: 'some-space-6',
        spaceName: 'Truck 2',
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 3 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset1Id,
        spaceId: 'some-space-2',
        spaceName: 'Door 2',
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment1Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset1Id,
        spaceId: 'some-space-1',
        spaceName: 'Storage Area 1',
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment1Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset1Id,
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment1Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment1Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A02
  {
    id: asset2Id,
    name: 'P00165',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '0-2603',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'b22c96710bfc40f8',
    bleId: 2,
    locations: {
      items: shipment2Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment2Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment2Activation.getTime(),
        (shipment2LastSeen - shipment2Activation) / shipment2Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset2Id,
        spaceId: 'some-space-1',
        spaceName: 'Truck 1',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 3 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset2Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment2Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset2Id,
        spaceId: 'some-space-1',
        spaceName: 'Racking 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(new Date(shipment2Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment2Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset2Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(new Date(shipment2Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment2Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment2Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A03
  {
    id: asset3Id,
    name: 'P23899',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '0-2603',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'fa2c1c8e024857e3',
    bleId: 3,
    locations: {
      items: shipment3Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment3Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment3Activation.getTime(),
        (shipment3LastSeen - shipment3Activation) / shipment3Locations.length,
        16.2,
        new Date(shipment3AlertStartMs + 7 * MIN_AS_MS).getTime(),
        new Date(NOW_MS - 1.5 * HOUR_AS_MS).getTime(),
      ),
    },
    inSpaces: [
      {
        assetId: asset3Id,
        spaceId: 'some-space-7',
        spaceName: 'Truck 4',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset3Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading bay 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment3Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset3Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment3Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset3Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment3Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment3Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
    alerts: [
      {
        category: 'temperature',
        type: 'aboveTempThreshold',
        message: 'Temperature threshold exceeded',
        // FUDGE: alert timestamp needs to match location timestamp
        timestamp: new Date(shipment3AlertStartMs).toISOString(),
        hasEnded: true,
      },
    ],
    timeline: [
      constructEvent(
        new Date(shipment3AlertStartMs + 7 * MIN_AS_MS),
        'Temperature threshold exceeded',
        'Temperature: 16.2°C',
        colours.yellowLight,
        new Date(NOW_MS - 1.5 * HOUR_AS_MS).getTime(),
        facility2.timeZone,
        false,
        alertIcons.temperature.aboveTempThreshold,
      ),
    ],
  },
  // A04
  {
    id: asset4Id,
    name: 'P00768',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-14610',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '091f415acd23daad',
    bleId: 4,
    locations: {
      items: shipment1Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment1Locations.length,
        temperatureThreshold2.min,
        temperatureThreshold2.max,
        shipment1Activation.getTime(),
        (shipment1LastSeen - shipment1Activation) / shipment1Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset4Id,
        spaceId: 'some-space-6',
        spaceName: 'Truck 3',
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 3 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset4Id,
        spaceId: 'some-space-2',
        spaceName: 'Door 2',
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment1Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset4Id,
        spaceId: 'some-space-1',
        spaceName: 'Storage Area 1',
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment1Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset4Id,
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment1Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment1Id,
    temperatureThresholds: [
      temperatureThreshold2,
    ],
  },
  // A05
  {
    id: asset5Id,
    name: 'P00232',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-14607',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '77af2e1b1a09bead',
    bleId: 5,
    locations: {
      items: shipment2Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment2Locations.length,
        temperatureThreshold2.min,
        temperatureThreshold2.max,
        shipment2Activation.getTime(),
        (shipment2LastSeen - shipment2Activation) / shipment2Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset5Id,
        spaceId: 'some-space-1',
        spaceName: 'Truck 1',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 3 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset5Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment2Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset5Id,
        spaceId: 'some-space-1',
        spaceName: 'Racking 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(new Date(shipment2Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment2Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset5Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(new Date(shipment2Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment2Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment2Id,
    temperatureThresholds: [
      temperatureThreshold2,
    ],
  },
  // A06
  {
    id: asset6Id,
    name: 'C11483',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-14610',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '5e3829cec90e8e36',
    bleId: 6,
    locations: {
      items: shipment3Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment3Locations.length,
        temperatureThreshold2.min,
        temperatureThreshold2.max,
        shipment3Activation.getTime(),
        (shipment3LastSeen - shipment3Activation) / shipment3Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset3Id,
        spaceId: 'some-space-7',
        spaceName: 'Truck 4',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset3Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading bay 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment3Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset3Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment3Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset3Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment3Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment3Id,
    temperatureThresholds: [
      temperatureThreshold2,
    ],
  },
  // A07
  {
    id: asset7Id,
    name: 'P00684',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-73320',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '5a3ec7c6bad7f96d',
    bleId: 7,
    locations: {
      items: shipment1Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment1Locations.length,
        temperatureThreshold3.min,
        temperatureThreshold3.max,
        shipment1Activation.getTime(),
        (shipment1LastSeen - shipment1Activation) / shipment1Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset7Id,
        spaceId: 'some-space-6',
        spaceName: 'Truck 2',
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 3 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset7Id,
        spaceId: 'some-space-2',
        spaceName: 'Door 2',
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment1Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset7Id,
        spaceId: 'some-space-1',
        spaceName: 'Storage Area 2',
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(new Date(shipment1Activation).getTime() - 6 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment1Departure - 0.45 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset7Id,
        facilityId: facility1.id,
        facilityName: facility1.name,
        facilityTimeZone: facility1.timeZone,
        enteredOn: new Date(shipment1Departure - 0.45 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment1Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment1Id,
    temperatureThresholds: [
      temperatureThreshold3,
    ],
  },
  // A08
  {
    id: asset8Id,
    name: 'P00457',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-84411',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '465e92e411a81254',
    bleId: 8,
    locations: {
      items: shipment2Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment2Locations.length,
        temperatureThreshold3.min,
        temperatureThreshold3.max,
        shipment2Activation.getTime(),
        (shipment2LastSeen - shipment2Activation) / shipment2Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset8Id,
        spaceId: 'some-space-7',
        spaceName: 'Truck 1',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset8Id,
        spaceId: 'some-space-2',
        spaceName: 'Door 2',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment2Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset8Id,
        spaceId: 'some-space-3',
        spaceName: 'Racking Area 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 0.45 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment2Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset8Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment2Departure - 0.45 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment2Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment2Id,
    temperatureThresholds: [
      temperatureThreshold3,
    ],
  },
  // A09
  {
    id: asset9Id,
    name: 'P00906',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-76443',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'e6cdb749bf615622',
    bleId: 9,
    locations: {
      items: shipment3Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment3Locations.length,
        temperatureThreshold3.min,
        temperatureThreshold3.max,
        shipment3Activation.getTime(),
        (shipment3LastSeen - shipment3Activation) / shipment3Locations.length,
        11.5,
        new Date(shipment3AlertStartMs).getTime(),
      ),
    },
    inSpaces: [
      {
        assetId: asset3Id,
        spaceId: 'some-space-7',
        spaceName: 'Truck 4',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset3Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading bay 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment3Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset3Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment3Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset3Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment3Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment3Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment3Id,
    temperatureThresholds: [
      temperatureThreshold3,
    ],
    alerts: [
      {
        category: 'temperature',
        type: 'aboveTempThreshold',
        message: 'Temperature threshold exceeded',
        // FUDGE: alert timestamp needs to match location timestamp
        timestamp: new Date(shipment3AlertStartMs).toISOString(),
      },
    ],
    timeline: [
      constructEvent(
        new Date(shipment3AlertStartMs),
        'Temperature threshold exceeded',
        'Temperature: 11.5°C',
        colours.redDark,
        null,
        facility2.timeZone,
        false,
        alertIcons.temperature.aboveTempThreshold,
      ),
    ],
  },
  // A10
  {
    id: asset10Id,
    name: 'P00560',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '0-2845',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'f1f51df927e29a4d',
    bleId: 10,
    locations: {
      items: shipment4Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment4Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment4Activation.getTime(),
        (shipment4LastSeen - shipment4Activation) / shipment4Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset10Id,
        spaceId: 'some-space-8',
        spaceName: 'Truck 5',
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset10Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading bay 3',
        facilityId: facility3.id,
        facilityName: facility3.name,
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 2 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment4Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset10Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 2',
        facilityId: facility3.id,
        facilityName: facility3.name,
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment4Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset10Id,
        facilityId: facility3.id,
        facilityName: facility3.name,
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment4Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment4Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
    alerts: [
      {
        category: 'consignment',
        type: 'separatedFromConsignment',
        message: `Separated from shipment ${shipment4Name}`,
        timestamp: new Date(shipment4AlertStartMs).toISOString(),
      },
    ],
    timeline: [
      constructEvent(
        new Date(shipment4AlertStartMs),
        'Separated from shipment',
        shipment4Name,
        colours.redDark,
        null,
        facility3.timeZone,
        false,
        alertIcons.consignment.separatedFromConsignment,
      ),
    ],
  },
  // A11
  {
    id: asset11Id,
    name: 'P00021',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_002',
      name: 'Pallet',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '0-2603',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'b7d7e36754378424',
    bleId: 11,
    locations: {
      items: shipment4Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment4Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment4Activation.getTime(),
        (shipment4LastSeen - shipment4Activation) / shipment4Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset11Id,
        spaceId: 'some-space-8',
        spaceName: 'Truck 5',
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset11Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility3.id,
        facilityName: facility3.name,
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 2 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment4Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset11Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility3.id,
        facilityName: facility3.name,
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment4Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset11Id,
        facilityId: facility3.id,
        facilityName: facility3.name,
        facilityTimeZone: facility3.timeZone,
        enteredOn: new Date(shipment4Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment4Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment4Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A12
  {
    id: asset12Id,
    name: 'C12621',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-73332',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '66e465ccfb850276',
    bleId: 12,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A13
  {
    id: asset13Id,
    name: 'C12622',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-3312',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'de959d9e3ba8fb36',
    bleId: 13,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A14
  {
    id: asset14Id,
    name: 'C12623',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-3312',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '6736bbf63bb46f17',
    bleId: 14,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A15
  {
    id: asset15Id,
    name: 'C12624',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-3312',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '931ec89c6f0cabed',
    bleId: 15,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A16
  {
    id: asset16Id,
    name: 'C12801',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-3312',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '9725f6075582ad59',
    bleId: 16,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A17
  {
    id: asset17Id,
    name: 'C12712',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-3312',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'd911f209357587b9',
    bleId: 17,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A18
  {
    id: asset18Id,
    name: 'C12637',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '1-3312',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'f210f0bcde297b7c',
    bleId: 18,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A19
  {
    id: asset19Id,
    name: 'C11927',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-1637',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'a3e4118680d9e9ff',
    bleId: 19,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A20
  {
    id: asset20Id,
    name: 'C12027',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-1637',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'dd5d1aa56010e1e3',
    bleId: 20,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A21
  {
    id: asset21Id,
    name: 'C12127',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-1637',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '96a9a67f58f8d5b6',
    bleId: 21,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A22
  {
    id: asset22Id,
    name: 'C12227',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-1637',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: 'aa2cf6a2894ed133',
    bleId: 22,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
  // A23
  {
    id: asset23Id,
    name: 'C12327',
    createdOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    lastSeen: new Date(NOW_MS - 0.1 * HOUR_AS_MS).toISOString(),
    assetType: {
      id: 'type_001',
      name: 'Case',
      fields: [
        {
          key: 'textField1',
          name: 'SKU',
          value: '3-1637',
          dataType: 'Text',
        },
      ],
    },
    labelAddedOn: new Date(NOW_MS - 10 * DAY_AS_MS).toISOString(),
    nfcId: '62dc97041bbf84ef',
    bleId: 23,
    locations: {
      items: shipment5Locations,
    },
    temperatures: {
      items: constructTemperatures(
        shipment5Locations.length,
        temperatureThreshold1.min,
        temperatureThreshold1.max,
        shipment5Activation.getTime(),
        (shipment5LastSeen - shipment5Activation) / shipment5Locations.length,
      ),
    },
    inSpaces: [
      {
        assetId: asset13Id,
        spaceId: 'some-space-9',
        spaceName: 'Truck 6',
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 6 * MIN_AS_MS).toISOString(),
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-2',
        spaceName: 'Loading Bay 3',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-6',
        spaceName: 'Build Up',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 0.4 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
      {
        assetId: asset13Id,
        spaceId: 'some-space-3',
        spaceName: 'Storage Area 1',
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 2.5 * HOUR_AS_MS).toISOString(),
        exitedOn: new Date(shipment5Departure - 1.25 * HOUR_AS_MS).toISOString(),
        hasExited: true,
      },
    ],
    inFacilities: [
      {
        assetId: asset13Id,
        facilityId: facility2.id,
        facilityName: facility2.name,
        facilityTimeZone: facility2.timeZone,
        enteredOn: new Date(shipment5Departure - 3 * HOUR_AS_MS).toISOString(),
        exitedOn: shipment5Departure.toISOString(),
        hasExited: true,
      },
    ],
    consignmentId: shipment5Id,
    temperatureThresholds: [
      temperatureThreshold1,
    ],
  },
];

const [
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
] = assets;

export const shipments = [
  // Shipment 1
  {
    id: shipment1Id,
    trackingRef: shipment1Name,
    origin: {
      where: { facility: { id: facility1Id } },
      departure: { actual: shipment1Departure.toISOString() },
    },
    destination: {
      where: { facility: { id: facility2Id } },
      when: { estimated: new Date(NOW_MS + 3.5 * HOUR_AS_MS).toISOString() },
    },
    pieceCount: 3,
    alerts: [],
    timeline: [
      constructEvent(
        shipment1Departure,
        'Truck 2',
        '',
        colours.active,
        null,
        facility1.timeZone,
        false,
        icons.truck,
      ),
      constructEvent(
        shipment1Activation,
        facility1.name,
        '',
        colours.gray,
        shipment1Departure,
        facility1.timeZone,
        false,
        icons.facility,
      ),
      constructEvent(
        shipment1Activation,
        'Shipment activated',
        `Tracking ref ${shipment1Name}`,
        colours.start,
        null,
        facility1.timeZone,
        true,
      ),
    ],
    locations: {
      items: shipment1Locations,
    },
  },
  // Shipment 2
  {
    id: shipment2Id,
    trackingRef: shipment2Name,
    origin: {
      where: { facility: { id: facility2Id } },
      departure: { actual: shipment2Departure.toISOString() },
    },
    destination: {
      where: { facility: { id: facility3Id } },
      when: { estimated: new Date(NOW_MS + 0.5 * HOUR_AS_MS).toISOString() },
    },
    pieceCount: 3,
    alerts: [],
    timeline: [
      constructEvent(
        shipment2Departure,
        'Truck 3',
        '',
        colours.active,
        null,
        facility2.timeZone,
        false,
        icons.truck,
      ),
      constructEvent(
        shipment2Activation,
        facility2.name,
        '',
        colours.gray,
        shipment2Departure,
        facility2.timeZone,
        false,
        icons.facility,
      ),
      constructEvent(
        shipment2Activation,
        'Shipment activated',
        `Tracking ref ${shipment2Name}`,
        colours.start,
        null,
        facility2.timeZone,
        true,
      ),
    ],
    locations: {
      items: shipment2Locations,
    },
  },
  // Shipment 3
  {
    id: shipment3Id,
    trackingRef: shipment3Name,
    origin: {
      where: { facility: { id: facility1Id } },
      departure: { actual: shipment3Departure.toISOString() },
    },
    destination: {
      where: { facility: { id: facility2Id } },
      when: { estimated: new Date(NOW_MS + 1.3 * DAY_AS_MS).toISOString() },
    },
    pieceCount: 3,
    alerts: [
      {
        category: 'temperature',
        type: 'aboveTempThreshold',
        message: 'Temperature threshold exceeded',
        timestamp: new Date(shipment3AlertStartMs).toISOString(),
      },
    ],
    timeline: [
      constructEvent(
        shipment3Departure,
        'Truck 4',
        '',
        colours.active,
        null,
        facility2.timeZone,
        false,
        icons.truck,
      ),
      constructEvent(
        new Date(shipment3AlertStartMs),
        'Temperature threshold exceeded',
        '2 assets affected',
        colours.redDark,
        null,
        facility2.timeZone,
        false,
        alertIcons.temperature.aboveTempThreshold,
        [
          constructEvent(
            new Date(shipment3AlertStartMs + 7 * MIN_AS_MS),
            `${asset3.assetType.name} ${asset3.name} exceeded ${asset3.temperatureThresholds[0].max}°C`,
            `Temperature: 16.2°C (current ${asset3.temperatures.items[0].degreesCelcius}°C)`,
            colours.yellowLight,
            new Date(NOW_MS - 30 * MIN_AS_MS).getTime(),
            facility2.timeZone,
            false,
            alertIcons.temperature.aboveTempThreshold,
            null,
            {
              to: {
                name: routeNames.assets.detail,
                params: {
                  id: asset3.id,
                },
                query: {
                  tab: itemDetailTabSlugs.temperature,
                },
              },
            },
          ),
          constructEvent(
            new Date(shipment3AlertStartMs),
            `${asset9.assetType.name} ${asset9.name} exceeded ${asset9.temperatureThresholds[0].max}°C`,
            `Temperature: 11.5°C (current ${asset9.temperatures.items[0].degreesCelcius}°C)`,
            colours.redDark,
            null,
            facility2.timeZone,
            false,
            alertIcons.temperature.aboveTempThreshold,
            null,
            {
              to: {
                name: routeNames.assets.detail,
                params: {
                  id: asset9.id,
                },
                query: {
                  tab: itemDetailTabSlugs.temperature,
                },
              },
            },
          ),
        ],
      ),
      constructEvent(
        shipment3Activation,
        facility1.name,
        '',
        colours.gray,
        shipment3Departure,
        facility1.timeZone,
        false,
        icons.facility,
      ),
      constructEvent(
        shipment3Activation,
        'Shipment activated',
        `Tracking ref ${shipment3Name}`,
        colours.start,
        null,
        facility1.timeZone,
        true,
      ),
    ],
    locations: {
      items: shipment3Locations,
    },
  },
  // Shipment 4
  {
    id: shipment4Id,
    trackingRef: shipment4Name,
    origin: { where: { facility: { id: facility2Id } } },
    destination: {
      where: { facility: { id: facility3Id } },
      when: { estimated: new Date(NOW_MS + 1.2 * HOUR_AS_MS).toISOString() },
    },
    pieceCount: 2,
    alerts: [
      {
        category: 'consignment',
        type: 'separatedFromConsignment',
        message: '1 pallet missing',
        timestamp: new Date(shipment4AlertStartMs).toISOString(),
      },
    ],
    timeline: [
      constructEvent(
        shipment4Departure,
        'Truck 5',
        '',
        colours.active,
        null,
        facility3.timeZone,
        false,
        icons.truck,
      ),
      constructEvent(
        new Date(shipment4AlertStartMs),
        'Split shipment',
        '1 pallet missing',
        colours.redDark,
        null,
        facility3.timeZone,
        false,
        alertIcons.consignment.separatedFromConsignment,
        [
          constructEvent(
            new Date(shipment4AlertStartMs),
            `${asset10.assetType.name} ${asset10.name} separated from shipment`,
            '',
            colours.redDark,
            null,
            facility3.timeZone,
            false,
            alertIcons.consignment.separatedFromConsignment,
            null,
            {
              to: {
                name: routeNames.assets.detail,
                params: {
                  id: asset10.id,
                },
                query: {
                  tab: itemDetailTabSlugs.timeline,
                },
              },
            },
          ),
        ],
      ),
      constructEvent(
        shipment4Activation,
        facility2.name,
        '',
        colours.gray,
        shipment4Departure,
        facility2.timeZone,
        false,
        icons.facility,
      ),
      constructEvent(
        shipment4Activation,
        'Shipment activated',
        `Tracking ref ${shipment4Name}`,
        colours.start,
        null,
        facility2.timeZone,
        true,
      ),
    ],
    locations: {
      items: shipment4Locations,
    },
  },
  // Shipment 5
  {
    id: shipment5Id,
    trackingRef: shipment5Name,
    origin: {
      where: { facility: { id: facility2Id } },
      departure: { actual: shipment5Departure.toISOString() },
    },
    destination: {
      where: { facility: { id: facility4Id } },
      when: { estimated: new Date(NOW_MS + 1.3 * HOUR_AS_MS).toISOString() },
    },
    pieceCount: 12,
    alerts: [],
    timeline: [
      constructEvent(
        shipment5Departure,
        'Truck 6',
        '',
        colours.active,
        null,
        facility2.timeZone,
        false,
        icons.truck,
      ),
      constructEvent(
        shipment5Activation,
        facility2.name,
        '',
        colours.gray,
        shipment5Departure,
        facility2.timeZone,
        false,
        icons.facility,
      ),
      constructEvent(
        shipment5Activation,
        'Shipment activated',
        `Tracking ref ${shipment5Name}`,
        colours.start,
        null,
        facility2.timeZone,
        true,
      ),
    ],
    locations: {
      items: shipment5Locations,
    },
  },
];
