/* eslint-disable no-param-reassign */
import api from '../api';
import { assetFilterSelectedKeys, pollingDataInterval } from '../constants';
import assetInSpaces from './assets/assetInSpaces';
import assetInFacilities from './assets/assetInFacilities';
import { logger } from '../mixins/logging/logger';

const defaultSearchTerm = '';

const getDefaultFiltersState = () => ({
  [assetFilterSelectedKeys.consignments]: '',
  [assetFilterSelectedKeys.facilities]: '',
  [assetFilterSelectedKeys.zones]: '',
});

const getDefaultState = () => ({
  isFakeData: false,
  isLoadingAssets: false,
  isLoadingAsset: false,
  isLoadingAssetLocations: false,
  isLoadingAssetTemperatures: false,
  isPollingAsset: false,
  isPollingAssets: false,
  assets: [],
  totalAssets: 0,
  nextToken: null,
  searchTerm: defaultSearchTerm,
  errorMessage: '',
  getAssetsCallCount: 0, // to ensure we only use the latest query results
  filters: getDefaultFiltersState(),
  subscriptions: {},
  assetsPollingId: null,
  assetPollingId: null,
});

const unSubscribeToAllSubscriptions = (state) => {
  Object.keys(state.subscriptions).forEach((assetId) => {
    try {
      state.subscriptions[assetId].unsubscribe();
    } catch (error) {
      //
    }
  });
};

function parseAssetTemperatures(asset, temperatures) {
  let { nextToken } = temperatures;
  const items = [];

  // use oldest nextToken
  if (asset.temperatures.items.length > 0 && temperatures.items.length > 0) {
    const existingLatestTimestamp = new Date(asset.temperatures.items.slice(-1)[0].timestamp)
      .getTime();
    const newLatestTimestamp = new Date(temperatures.items.slice(-1)[0].timestamp).getTime();

    nextToken = existingLatestTimestamp < newLatestTimestamp
      ? asset.temperatures.nextToken : temperatures.nextToken;
  }

  // Only add new data
  // - unique by timestamp
  const existingTimestamps = asset.temperatures.items.map((t) => t.timestamp);
  items.push(...temperatures.items
    .filter((t) => !existingTimestamps.includes(t.timestamp)));

  // add the existing data
  items.push(...asset.temperatures.items);

  // sort (newest first)
  return {
    items: items.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()),
    nextToken,
  };
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      if (state.isFakeData) return;
      unSubscribeToAllSubscriptions(state);
      Object.assign(state, getDefaultState());
    },
    SET_FAKE_DATA(state, assets = []) {
      state.isFakeData = true;
      state.assets = assets;
      state.totalAssets = assets.length;
    },
    SET_FILTERS(state, filters) {
      filters.forEach(({ key, value }) => {
        state.filters[key] = value;
      });
    },
    RESET_FILTERS(state) {
      state.filters = Object.assign(state.filters, getDefaultFiltersState());
    },
    ADD_ASSET(state, asset) {
      if (state.isFakeData) return;
      const existingAsset = state.assets.find(({ id }) => asset.id === id);
      // locations
      if (asset.locations && Array.isArray(asset.locations.items)) {
        asset.locations.items = asset.locations.items
          .filter((loc) => loc.latitude && loc.longitude); // remove blank

        if (existingAsset) {
          // Use get asset location, in place of searchAssets single location...
          if (asset.locations.items.length > 0
              && existingAsset?.locations?.items
              && Array.isArray(existingAsset.locations.items)
              && existingAsset.locations.items.length === 1
              && (existingAsset.locations.items[0]
                .timestamp === asset.locations.items[0].timestamp
                || existingAsset.locations.items[0]
                  .timestamp === asset.locations.items[0].updatedAt)) {
            // existingAsset location was populated from searchOrganisationAssets
            //  and doesn't have address data
            existingAsset.locations.items.splice(0, 1);
          }

          // use oldest nextToken
          if (existingAsset?.locations?.items
              && existingAsset.locations.items.length > 0 && asset.locations.items.length > 0
              && new Date(existingAsset.locations.items.slice(-1)[0].timestamp)
                .getTime() < new Date(asset.locations.items.slice(-1)[0].timestamp).getTime()) {
            asset.locations.nextToken = existingAsset.locations.nextToken;
          }

          asset.locations.items = asset.locations.items
            .filter((loc) => !existingAsset?.locations?.items
                || !existingAsset.locations.items // only new
                  .find((existingLoc) => loc.timestamp === existingLoc.timestamp));

          asset.locations.items.push(...existingAsset.locations.items);
          asset.locations.items = asset.locations.items
            .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        }
      }

      // temperatures
      if (asset.temperatures && Array.isArray(asset.temperatures.items)) {
        asset.temperatures = existingAsset && existingAsset.temperatures
          ? parseAssetTemperatures(existingAsset, asset.temperatures)
          : asset.temperatures;
      }

      if (existingAsset) {
        // retain the original order
        state.assets = state.assets.map((a) => (a.id === asset.id ? asset : a));
      } else {
        state.assets.push(asset);
      }
    },
    ADD_LOCATIONS(state, { id, locations }) {
      if (state.isFakeData) return;
      const asset = state.assets.find((a) => a.id === id);
      if (asset) {
        asset.locations.items.push(...locations.items);
        asset.locations.nextToken = locations.nextToken;
      }
    },
    ADD_TEMPERATURES(state, { assetId, temperatures }) {
      if (state.isFakeData) return;
      const asset = state.assets.find((a) => a.id === assetId);

      if (asset) {
        if (!asset.temperatures) {
          asset.temperatures = { items: [], nextToken: null };
        }

        asset.temperatures = parseAssetTemperatures(asset, temperatures);
      }
    },
    REMOVE_ASSET(state, removedAssetId) {
      if (state.isFakeData) return;
      const assets = state.assets.filter(({ id }) => id !== removedAssetId);
      state.assets = [...assets];
    },
    UPDATE_ASSET(state, editedAsset) {
      if (state.isFakeData) return;
      state.assets = state.assets
        .map((asset) => (asset.id === editedAsset.id ? editedAsset : asset));
    },
    SET_ASSETS(state, assets) {
      if (state.isFakeData) return;
      state.assets = [
        ...state.assets
          .filter((assetInState) => !assets.find((newAsset) => assetInState.id === newAsset.id)),
        ...assets.map((asset) => {
          const a = asset;

          // remove blank locations
          if (a.locations && Array.isArray(a.locations.items)) {
            // eslint-disable-next-line no-param-reassign
            a.locations.items = a.locations.items.filter((i) => i.latitude && i.longitude);
          }
          return a;
        }),
      ];
    },
    RESET_ASSETS(state) {
      if (state.isFakeData) return;
      state.assets = [];
    },
    UPDATE_ASSET_IN_SPACE(state, inSpace) {
      if (state.isFakeData) return;
      const asset = state.assets.find(({ id }) => inSpace.assetId === id);
      if (asset) {
        if (inSpace.hasExited && Array.isArray(asset.inSpaces)) {
          // Exit event
          asset.inSpaces = asset.inSpaces.filter(({ spaceId }) => inSpace.spaceId !== spaceId);
        } else if (!inSpace.hasExited) {
          // Enter event
          if (Array.isArray(asset.inSpaces)) {
            // if inSpace already exists, then modify, otherwise, add
            if (asset.inSpaces.find(({ spaceId }) => inSpace.spaceId === spaceId)) {
              asset.inSpaces = asset.inSpaces
                .map((is) => (is.spaceId === inSpace.spaceId ? inSpace : is));
            } else {
              asset.inSpaces.push(inSpace);
            }
          } else {
            asset.inSpaces = [inSpace];
          }
        }
      }
      state.assets = state.assets.map((a) => (a.id === asset.id ? asset : a));
    },
    SET_TOTAL_ASSETS(state, total) {
      if (state.isFakeData) return;
      state.totalAssets = total;
    },
    SET_NEXT_TOKEN(state, nextToken) {
      if (state.isFakeData) return;
      state.nextToken = nextToken;
    },
    RESET_NEXT_TOKEN(state) {
      state.nextToken = null;
    },
    SET_SEARCH_TERM(state, searchTerm) {
      if (!searchTerm || searchTerm === '') {
        state.searchTerm = defaultSearchTerm;
      } else {
        state.searchTerm = searchTerm;
      }
    },
    RESET_SEARCH_TERM(state) {
      state.searchTerm = defaultSearchTerm;
    },
    SET_ASSET_TEMPERATURE(state, { bleId, temperatureData }) {
      if (state.isFakeData) return;
      // combine new data with existing data...
      const asset = state.assets.find((a) => a.bleId === bleId);
      const temperatures = {}; // keyed by timestamp
      if (asset) {
        if (asset.temperatures && Array.isArray(asset.temperatures.items)) {
          asset.temperatures.items.forEach((t) => {
            temperatures[t.timestamp] = t;
          });
        }
        if (temperatureData && Array.isArray(temperatureData.items)) {
          // New data supercedes old (as it does in DynamoDB)
          temperatureData.items.forEach((t) => {
            // only show data since asset was created
            if (asset.createdOn
                && new Date(t.timestamp).getTime() >= new Date(asset.createdOn).getTime()) {
              temperatures[t.timestamp] = t;
            }
          });
        }
        if (!asset.temperatures) asset.temperatures = {};
        asset.temperatures.items = Object.values(temperatures)
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
    },
    START_LOADING_ASSET(state) {
      state.isLoadingAsset = true;
    },
    STOP_LOADING_ASSET(state) {
      state.isLoadingAsset = false;
    },
    START_LOADING_ASSET_LOCATIONS(state) {
      state.isLoadingAssetLocations = true;
    },
    STOP_LOADING_ASSET_LOCATIONS(state) {
      state.isLoadingAssetLocations = false;
    },
    START_LOADING_ASSET_TEMPERATURES(state) {
      state.isLoadingAssetTemperatures = true;
    },
    STOP_LOADING_ASSET_TEMPERATURES(state) {
      state.isLoadingAssetTemperatures = false;
    },
    START_LOADING_ASSETS(state) {
      state.isLoadingAssets = true;
    },
    STOP_LOADING_ASSETS(state) {
      state.isLoadingAssets = false;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      if (state.isFakeData) return;
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorMessage = '';
    },
    INCREMENT_GET_ASSETS_CALL_COUNT(state) {
      state.getAssetsCallCount += 1;
    },
    ADD_SUBSCRIPTION(state, { assetId, subscription }) {
      state.subscriptions[assetId] = subscription;
    },
    REMOVE_SUBSCRIPTION(state, assetId) {
      if (state.subscriptions[assetId]) {
        state.subscriptions[assetId].unsubscribe();
      }
    },
    START_POLLING_ASSET(state) { state.isPollingAsset = true; },
    STOP_POLLING_ASSET(state) { state.isPollingAsset = false; },
    SET_ASSET_POLLING_INTERVAL_ID(state, pollingIntervalId) {
      state.assetPollingId = pollingIntervalId;
    },
    RESET_ASSET_POLLING_INTERVAL_ID(state) {
      window.clearInterval(state.assetPollingId);
      state.assetPollingId = null;
    },
    START_POLLING_ASSETS(state) { state.isPollingAssets = true; },
    STOP_POLLING_ASSETS(state) { state.isPollingAssets = false; },
    SET_ASSETS_POLLING_INTERVAL_ID(state, pollingIntervalId) {
      state.assetsPollingId = pollingIntervalId;
    },
    RESET_ASSETS_POLLING_INTERVAL_ID(state) {
      window.clearInterval(state.assetsPollingId);
      state.assetsPollingId = null;
    },
  },
  actions: {
    async refreshGetAssets(context, data = {}) {
      context.commit('RESET_ASSETS');
      context.commit('RESET_NEXT_TOKEN');
      unSubscribeToAllSubscriptions(context.state);
      await context.dispatch('getAssets', data);
    },
    async getAssetByNfcId(context, { nfcId, skipRedirect }) {
      context.commit('START_LOADING_ASSET');
      try {
        return await api.getAssetByNfcId(nfcId, !skipRedirect);
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    startPollingAsset(context, { id, condition }) {
      context.commit('RESET_ASSET_POLLING_INTERVAL_ID');
      const pollingIntervalId = window.setInterval(async () => {
        try {
          if (!context.state.isLoadingAsset
            && !context.state.isPollingAsset
            && (typeof condition !== 'function' || condition())) {
            context.commit('START_POLLING_ASSET');
            const asset = await api.getAsset(id);
            context.commit('ADD_ASSET', asset);
          }
        } catch (error) {
          // error
        } finally {
          context.commit('STOP_POLLING_ASSET');
        }
      }, pollingDataInterval);
      context.commit('SET_ASSET_POLLING_INTERVAL_ID', pollingIntervalId);
    },
    stopPollingAsset(context) {
      context.commit('RESET_ASSET_POLLING_INTERVAL_ID');
    },
    async getAsset(context, id) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET');
      try {
        const asset = await api.getAsset(id);
        context.commit('ADD_ASSET', asset);
        // subscribe to changes in the asset
        if (!context.state.isPollingAsset) {
          context.dispatch('subscribeToAssetUpdates', asset);
        }
        return asset;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    async getAssetLocations(context, { nfcId, nextToken, id }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET_LOCATIONS');
      try {
        const locations = await api.getAssetLocations(nfcId, nextToken);
        context.commit('ADD_LOCATIONS', { id, locations });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ASSET_LOCATIONS');
      }
    },
    async getAssetTemperatures(context, assetId) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET_TEMPERATURES');
      try {
        const { nextToken } = (context.state.assets
          .find((a) => a.id === assetId) || {}).temperatures || {};

        const temperatures = await api.getAssetTemperatures(assetId, nextToken);

        context.commit('ADD_TEMPERATURES', { assetId, temperatures });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ASSET_TEMPERATURES');
      }
    },
    async getAllAssetTemperatures(context, assetId) {
      try {
        const { nextToken } = (context.state.assets
          .find((a) => a.id === assetId) || {}).temperatures || {};

        if (nextToken) {
          await context.dispatch('getAssetTemperatures', assetId);
        }

        const newNextToken = ((context.state.assets
          .find((a) => a.id === assetId) || {}).temperatures || {}).nextToken;

        // check whether there is still data to get
        if (newNextToken) {
          await context.dispatch('getAllAssetTemperatures', assetId);
        }
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      }
    },
    startPollingAssets(context, data = {}) {
      const { condition } = data;
      context.commit('RESET_ASSETS_POLLING_INTERVAL_ID');
      const pollingIntervalId = window.setInterval(async () => {
        try {
          if (!context.state.isLoadingAssets
            && !context.state.isPollingAssets
            && (typeof condition !== 'function' || condition())) {
            context.commit('START_POLLING_ASSETS');
            const assetsResponse = await api.getAssets(
              context.rootState.teams.selectedTeam.id,
              context.state.nextToken,
              context.state.searchTerm,
              context.state.filters,
            );
            context.commit('SET_ASSETS', assetsResponse.items);
          }
        } catch (error) {
          // error
        } finally {
          context.commit('STOP_POLLING_ASSETS');
        }
      }, pollingDataInterval);
      context.commit('SET_ASSETS_POLLING_INTERVAL_ID', pollingIntervalId);
    },
    stopPollingAssets(context) {
      context.commit('RESET_ASSETS_POLLING_INTERVAL_ID');
    },
    async getAssets(context, data = {}) {
      context.commit('INCREMENT_GET_ASSETS_CALL_COUNT');
      const getAssetsQueryId = context.state.getAssetsCallCount;
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSETS');
      try {
        const assetsResponse = await api.getAssets(
          context.rootState.teams.selectedTeam.id,
          context.state.nextToken,
          context.state.searchTerm,
          context.state.filters,
          data.sortOrder,
        );
        if (getAssetsQueryId === context.state.getAssetsCallCount) {
          // if we are searching or filtering, and zero assets are returned,
          // we don't want the empty state being displayed, so avoid
          // updating the total number. However, if none have been loaded up to
          // this point (E.g. if we refresh page on zones and come to assets list
          // from the zones screen 'view assets in zone') then we need to set total
          if (!context.getters.hasAssets) {
            context.commit('SET_TOTAL_ASSETS', assetsResponse.total);
          }
          context.commit('SET_NEXT_TOKEN', assetsResponse.nextToken);
          context.commit('SET_ASSETS', assetsResponse.items);

          // subscribe to changes in each asset loaded
          if (!context.state.isPollingAssets) {
            assetsResponse.items
              .forEach((asset) => context.dispatch('subscribeToAssetUpdates', asset));
          }
        }
      } catch (error) {
        if (getAssetsQueryId === context.state.getAssetsCallCount) {
          context.commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        if (getAssetsQueryId === context.state.getAssetsCallCount) {
          context.commit('STOP_LOADING_ASSETS');
        }
      }
    },
    async editAsset(context, { assetId, name }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET');
      try {
        const editedAsset = await api.editAsset(assetId, name);
        context.commit('UPDATE_ASSET', editedAsset);
        return editedAsset;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    async addAsset(context, asset) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET');
      try {
        const newAsset = await api.addAsset(
          context.rootState.teams.selectedTeam.id,
          asset.name,
          asset.label ? asset.label.nfcId : null,
          asset.label ? asset.label.location : null,
        );
        context.commit('SET_TOTAL_ASSETS', context.state.totalAssets + 1);
        context.commit('ADD_ASSET', newAsset);
        // subscribe to changes in the asset
        context.dispatch('subscribeToAssetUpdates', newAsset);
        return newAsset;
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
        return null;
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    async removeAsset(context, assetId) {
      context.commit('START_LOADING_ASSET');
      try {
        await api.removeAsset(context.rootState.teams.selectedTeam.id, assetId);
        context.commit('SET_TOTAL_ASSETS', context.state.totalAssets - 1);
        context.commit('REMOVE_ASSET', assetId);
        context.commit('REMOVE_SUBSCRIPTION', assetId);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message || 'Unknown error');
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    async addLabelToAsset(context, asset) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET');
      try {
        const editedAsset = await api.addLabelToAsset(
          asset.id,
          asset.label ? asset.label.nfcId : null,
          asset.label ? asset.label.location : null,
        );
        context.commit('UPDATE_ASSET', editedAsset);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    async addLabelTemperatureReadings(context, { bleId, readings }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET');
      try {
        const temperatureData = await api.addLabelTemperatureReadings(bleId, readings);
        context.commit('SET_ASSET_TEMPERATURE', { bleId, temperatureData });
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    async removeLabelFromAsset(context, asset) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING_ASSET');
      try {
        const editedAsset = await api.removeLabelFromAsset(asset.id, asset.nfcId);
        context.commit('UPDATE_ASSET', editedAsset);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING_ASSET');
      }
    },
    subscribeToAssetUpdates(context, asset) {
      context.commit('REMOVE_SUBSCRIPTION', asset.id);
      const subscription = api.subscribeToAssetUpdates(
        asset.id,
        (inSpace) => context.commit('UPDATE_ASSET_IN_SPACE', inSpace),
        (error) => {
          if (error?.error?.errors?.length && error?.error?.errors[0].message === 'Timeout disconnect') {
            logger.error('Assets subscription timeout error', {
              asset,
              selectedTeam: context.rootState.teams.selectedTeam,
              user: context.rootState.user.user,
            });
            // For now, commenting out the restart - which is causing the memory leak
            // context.dispatch('subscribeToAssetUpdates', asset);
          }
        },
      );
      context.commit('ADD_SUBSCRIPTION', { assetId: asset.id, subscription });
    },
  },
  getters: {
    hasCalledGetAssets(state) {
      return state.getAssetsCallCount > 0;
    },
    hasAssets(state) {
      return state.totalAssets > 0;
    },
    // We want to receive boolean so we convert a non-boolean value to
    // a boolean value with Boolean as a function.
    hasLocations(state) {
      return Boolean(state.assets.find((asset) => asset.locations?.items?.length));
    },
    hasTemperatures(state) {
      return Boolean(state.assets.find((asset) => asset.temperatures?.items?.length));
    },
    hasMoreAssets(state) {
      return state.nextToken !== null;
    },
    isSearching(state) {
      return state.searchTerm !== defaultSearchTerm;
    },
    filtersApplied(state) {
      const filtersApplied = [];
      Object.keys(state.filters)
        .forEach((key) => {
          if (state.filters[key] !== null
          && state.filters[key] !== '') {
            filtersApplied.push({
              key, value: state.filters[key],
            });
          }
        });
      return filtersApplied;
    },
    anyFiltersApplied(_, getters) {
      return getters.filtersApplied.length > 0;
    },
  },
  modules: {
    assetInSpaces,
    assetInFacilities,
  },
};
