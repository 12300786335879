<template>
  <MainWithDetailLayout
    :is-loading="!hasAssets && isLoading"
    :main-route-name="mainRootName"
    :hide-detail="!hasAssets && isLoading"
    :is-main-full-width="!hasAssets && isLinkLabelRoute"
    :is-detail-full-width="!hasAssets && isAddAssetRoute"
  >
    <EmptyState
      v-if="!isLoading && !hasAssets && !isAddAssetRoute && !areFiltersApplied"
      :background-source="backgroundSource"
      :items="contents"
    >
      <template
        v-if="!isLinkLabelRoute"
        #buttons
      >
        <Button
          :to="addAssetRoute"
          text="Add Asset"
        />
        <Button
          :on-click="clickLinkLabel"
          text="Link Label"
        />
      </template>
    </EmptyState>
    <VerticalContainer v-else-if="hasAssets || (areFiltersApplied && !isLoading)">
      <div class="assets-wrapper">
        <AssetsSearch
          v-if="includeSearch"
          class="assets-search-wrapper"
        />
        <AssetsTable
          :larger-gap-bottom="includeSearch"
          :smaller-gap-bottom="!includeSearch"
          :selected-id="$route.params.id"
          :display-has-label="!$root.workspaceFeatures.shipmentsDemo"
        />
      </div>
      <template #buttons>
        <Button
          :to="addAssetRoute"
          text="Add Asset"
          :disabled="$root.isViewer"
        />
        <Button
          :on-click="clickLinkLabel"
          text="Link Label"
          :disabled="$root.isViewer"
        />
        <Button
          v-if="hasLocations"
          :on-click="displayAssetsMap"
          text="Map"
        />
      </template>
    </VerticalContainer>
  </MainWithDetailLayout>
</template>
<script>
import MainWithDetailLayout from '../../components/organisms/layout/main-with-detail-layout';
import EmptyState from '../../components/organisms/assets/empty-state';
import AssetsTable from '../../components/organisms/assets/assets-table';
import AssetsSearch from '../../components/organisms/assets/assets-search';
import Button from '../../components/atoms/button/button';
import BackgroundImage from '../../assets/images/background-image.svg';
import { routeNames } from '../../constants';
import VerticalContainer from '../../components/organisms/layout/vertical-container';

export default {
  name: 'AssetsList',
  components: {
    MainWithDetailLayout,
    EmptyState,
    Button,
    AssetsTable,
    AssetsSearch,
    VerticalContainer,
  },
  computed: {
    areFiltersApplied() {
      return this.$store.getters['assets/anyFiltersApplied'];
    },
    includeSearch() {
      return this.$root.featureToggles.includeAssetsSearch();
    },
    mainRootName() {
      return routeNames.assets.list;
    },
    isAddAssetRoute() {
      return this.$route.name === routeNames.assets.add;
    },
    isLoading() {
      return this.$store.state.assets.isLoadingAssets;
    },
    hasAssets() {
      return this.$store.getters['assets/hasAssets'];
    },
    hasLocations() {
      return this.$store.getters['assets/hasLocations'];
    },
    addAssetRoute() {
      return { name: routeNames.assets.add };
    },
    backgroundSource() {
      return BackgroundImage;
    },
    contents() {
      return [
        'Your team does not have any assets yet',
        'To join a pre-existing team ask an administrator of that team to add you',
        'To add assets to your team click add assets at the bottom of this page',
      ];
    },
    isLinkLabelRoute() {
      return this.$route.name === routeNames.assets.linkLabel;
    },
    selectedTeamId() {
      return this.$route.params.teamId;
    },
    isMobileApp() {
      return this.$store.getters.isMobileApp;
    },
    isMobileGatewayActiveOrLoading() {
      return this.$store.state.mobileApp.isMobileGatewayActive
        && this.$store.state.mobileApp.isMobileGatewayLoading;
    },
  },
  watch: {
    selectedTeamId() {
      if (!this.hasAssets) {
        this.$store.dispatch('assets/getAssets');
      }
    },
    isMobileApp() {
      this.startPolling();
    },
  },
  created() {
    // setting inZones/inFacilities/inConsignments filters based on URL
    const { inZones, inFacilities, inConsignments } = this.$route.query || {};
    if (inZones) {
      this.$store.commit('assetsFilters/SET_SELECTED_ITEM', { key: 'zones', id: inZones });
      this.$store.commit('assets/SET_FILTERS', [{ key: 'selectedZoneId', value: inZones }]);
    } else if (inFacilities) {
      this.$store.commit('assetsFilters/SET_SELECTED_ITEM', { key: 'facilities', id: inFacilities });
      this.$store.commit('assets/SET_FILTERS', [{ key: 'selectedFacilityId', value: inFacilities }]);
    } else if (inConsignments) {
      this.$store.commit('assetsFilters/SET_SELECTED_ITEM', { key: 'consignments', id: inConsignments });
      this.$store.commit('assets/SET_FILTERS', [{ key: 'selectedConsignmentId', value: inConsignments }]);
    }

    if (!this.hasAssets) {
      this.$store.dispatch('assets/getAssets');
    } else {
      this.$store.dispatch('assets/refreshGetAssets');
    }

    this.startPolling();
  },
  beforeDestroy() {
    // stop data polling
    this.$store.dispatch('assets/stopPollingAssets');
  },
  destroyed() {
    this.$store.commit('assetsFilters/RESET_ALL_SELECTED_ITEMS');
    this.$store.commit('assets/RESET_FILTERS');
  },
  methods: {
    startPolling() {
      if (this.$root.featureToggles.includeAssetDataPolling(this.$store.state.mobileApp, this)) {
        // only get new data if not already getting it
        // and if on the AssetsList screen (not asset detail - which is a child of this)
        this.$store.dispatch(
          'assets/startPollingAssets',
          { condition: () => this.$route.name === routeNames.assets.list },
        );
      }
    },
    clickLinkLabel() {
      if (this.$store.getters.isMobileApp) {
        this.$store.dispatch('mobileApp/startNfcScan');
      } else {
        this.$router.push({ name: routeNames.assets.linkLabel });
      }
    },
    displayAssetsMap() {
      this.$router.push({ name: routeNames.assets.map }).catch(() => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.assets-wrapper {
  height: 100%;
}
.assets-search-wrapper {
  margin-bottom: 15px
}
</style>
