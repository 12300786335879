<template>
  <div
    ref="infiniteTimelineContainer"
    class="infinite-timeline-container"
    :class="{
      'is-expand-content': isExpandContent
    }"
  >
    <div
      class="timeline-wrapper"
      :style="{
        'height': !isExpandContent && wrapperHeight + 'px'
      }"
    >
      <Timeline
        v-if="timelineIsVisible"
        :items="items"
        :display-header="displayHeader"
        :left-header="leftHeader"
        :right-header="rightHeader"
        :hide-time-zone="hideTimeZone"
        :expand-content-has-more-data="expandContentHasMoreData"
        :is-loading="isLoading"
        :is-expand-content="isExpandContent"
      />
      <Loader
        :is-loading="showLoader"
        with-padding-top
      />
      <infinite-loading
        v-if="hasMoreData && !isLoading"
        :distance="100"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
      >
        <div slot="spinner" />
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>
    <resize-observer
      v-if="timelineIsVisible && !isExpandContent"
      @notify="setWrapperHeight"
    />
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import Timeline from './timeline';
import Loader from '../../atoms/loader/loader';

export default {
  components: {
    InfiniteLoading,
    Timeline,
    Loader,
  },
  props: {
    items: { type: Array, default: () => ([]) },
    isLoading: { type: Boolean, default: false },
    hasMoreData: { type: Boolean, default: false },
    displayHeader: { type: Boolean, default: false },
    leftHeader: { type: String, default: 'From' },
    rightHeader: { type: String, default: 'To' },
    hideTimeZone: { type: Boolean, default: false },
    expandContentHasMoreData: { type: Function, default: () => false },
    isExpandContent: { type: Boolean, default: false },
  },
  data() {
    return {
      wrapperHeight: 0,
      infiniteId: +new Date(),
    };
  },
  computed: {
    hasData() {
      return this.items.length > 0;
    },
    timelineIsVisible() {
      return this.items.length > 0;
    },
    showLoader() {
      return this.isLoading && (!this.isExpandContent || this.hasMoreData);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setWrapperHeight();
    });
  },
  methods: {
    setWrapperHeight() {
      this.wrapperHeight = this.$refs.infiniteTimelineContainer.clientHeight;
    },
    infiniteHandler($state) {
      this.$emit('moreData');
      $state.loaded();
      if (!this.hasMoreData) {
        $state.complete();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
.infinite-timeline-container {
  position: relative;
  width: 100%;
  height: 100%;
  &.is-expand-content {
    .timeline-wrapper {
      overflow-y: visible;
      min-height: 80px;
    }
  }
}
.timeline-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 150px;
}
</style>
