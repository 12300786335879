<template>
  <div class="popup">
    <div class="content">
      <div class="is-bold">
        <v-icon
          v-if="data.icon"
          :color="data.iconColor || null"
        >
          {{ data.icon }}
        </v-icon>
        <div>
          {{ data.name }}
        </div>
      </div>
      <div v-if="data.eta">
        ETA: {{ data.eta }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MapPopup',
  props: {
    data: { type: Object, default: null },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.popup {
  color: $black;
  text-align: center;
  padding: 0px 10px;
  .content {
    width: 110px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    > div {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}
</style>
