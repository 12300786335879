/* eslint-disable no-param-reassign */
function randomBetween(min, max) {
  if (min > max) {
    console.log('HERE');
    const max2 = max;
    const min2 = min;
    min = max2;
    max = min2;
  }
  return Math.floor(Math.random() * (10 * max - 10 * min + 1) + 10 * min) / 10;
}

const MIN_AS_MS = 60 * 1000;

function constructNewTemperature(temperature, max, min, isExcursion, attempt = 0) {
  const delta = isExcursion
    ? randomBetween(-0.5, 1)
    : randomBetween((min - temperature) / 10, (max - temperature) / 10);
  const t = Math.round(10 * (temperature + delta)) / 10;

  if (attempt > 4) {
    console.log('attempt', attempt);
    return temperature;
  }

  if (!isExcursion && (t > max || t < min)) {
    return constructNewTemperature(temperature, max, min, isExcursion, attempt + 1);
  }
  if (isExcursion && t < max) {
    return constructNewTemperature(temperature, max, min, isExcursion, attempt + 1);
  }

  return t;
}

export function constructTemperatures(
  numTemperatures,
  min,
  max,
  startTimestamp,
  intervalMs = 10 * MIN_AS_MS,
  excursionTemperature = null,
  excursionStartTs = null,
  excursionEndTs = null,
) {
  const temperatures = [];

  let timestamp = startTimestamp;
  let temperature = randomBetween(min, max);
  let excursion = false;
  const excursionStartTimestamp = excursionStartTs;
  const excursionEndTimestamp = excursionEndTs;

  if (intervalMs !== 10 * MIN_AS_MS) {
    numTemperatures *= (intervalMs / (10 * MIN_AS_MS));
    intervalMs = 10 * MIN_AS_MS;
  }

  // eslint-disable-next-line no-unreachable-loop
  for (let i = 0; i < numTemperatures; i++) {
    temperature = constructNewTemperature(temperature, max, min, excursion);

    if (excursionEndTimestamp !== null && excursion && timestamp >= excursionEndTimestamp) {
      excursion = false;
      temperature = randomBetween(min, max);
    }

    if (excursionStartTimestamp !== null && !excursion
        && timestamp >= excursionStartTimestamp
        && (excursionEndTimestamp === null || timestamp < excursionEndTimestamp)) {
      excursion = true;
      temperature = excursionTemperature;
    }

    temperatures.push({
      timestamp: new Date(timestamp).toISOString(),
      // [sic] this is a bug, it should be degreesCelsius
      degreesCelcius: temperature,
      // degreesCelcius: excursion ? excursionTemperature : temperature,
    });

    timestamp += intervalMs;
  }

  return temperatures.reverse();
}
