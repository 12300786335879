<template>
  <div class="shipment-timeline-container">
    <InfiniteTimeline
      :items="items"
      :display-header="true"
      :is-loading="isLoading"
    />
  </div>
</template>
<script>
import InfiniteTimeline from '../../molecules/timeline/infinite-timeline';
import { shipments } from '../../../data/shipments-demo';

export default {
  components: {
    InfiniteTimeline,
  },
  props: {
    shipmentId: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    shipment() { return shipments.find((shipment) => shipment.id === this.shipmentId); },
    items() { return this.shipment.timeline; },
  },
  watch: {
    shipmentId() { this.loadData(); },
  },
  created() { this.loadData(); },
  methods: {
    loadData() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 800);
    },
  },
};
</script>
<style lang="scss" scoped>
.shipment-timeline-container {
  height: calc(100% - 35px);
}
</style>
