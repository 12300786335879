<template>
  <ItemsTable
    :headers="headers"
    :rows="rows"
    :get-data="getData"
    :has-more-data="hasMoreData"
    :is-loading="isLoading"
    @clickRow="clickRow"
  />
</template>
<script>
import { routeNames, alertIcons } from '../../../constants';
import ItemsTable from '../table/items-table';
import { facilities, shipments } from '../../../data/shipments-demo';
import { formatDate } from '../../../mixins/date-time-helper';

export default {
  name: 'ShipmentsTable',
  components: {
    ItemsTable,
  },
  props: {
    selectedId: { type: String, default: null },
    itemIds: { type: Array, default: () => [] },
  },
  computed: {
    hasMoreData() { return false; },
    isLoading() { return false; },
    headers() {
      const headers = [
        { name: 'Tracking Ref', key: 'trackingRef' },
        { name: 'Asset count', key: 'pieceCount' },
        { name: 'Origin', key: 'origin' },
        { name: 'Destination', key: 'destination' },
        { name: 'ETA', key: 'eta' },
        { name: 'Status', key: 'status' },
      ];

      return headers;
    },
    rows() {
      const rows = shipments
        .filter(({ id }) => this.itemIds.length === 0 || this.itemIds.includes(id))
        .map(({
          id, trackingRef, pieceCount, origin, destination, alerts,
        }) => ({
          isSelected: this.selectedId && this.selectedId === id,
          id,
          trackingRef,
          pieceCount,
          origin: facilities.find((facility) => facility.id === origin.where.facility.id).name,
          destination: facilities
            .find((facility) => facility.id === destination.where.facility.id).name,
          etaTimestamp: destination.when && destination.when.estimated
            ? new Date(destination.when.estimated).getTime()
            : 0,
          eta: destination.when && destination.when.estimated
            ? formatDate(new Date(destination.when.estimated), true)
            : '',
          status: {
            value: alerts.length > 0 ? alerts[0].message : 'OK',
            icon: alerts.length > 0 ? alertIcons[alerts[0].category][alerts[0].type] : '',
            iconColor: 'warning',
          },
        })).sort((a, b) => a.etaTimestamp - b.etaTimestamp);

      return rows;
    },
  },
  methods: {
    clickRow(row) {
      this.$router.push({
        name: routeNames.shipments.detail,
        params: {
          id: row.id,
        },
      }).catch(() => { });
    },
    getData() {
      // get data
    },
  },
};
</script>
