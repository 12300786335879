<template>
  <v-timeline
    class="timeline-container"
    :class="{
      'is-expand-content': isExpandContent
    }"
  >
    <HeaderRow
      v-if="displayHeader"
      :left-header="leftHeader"
      :right-header="rightHeader"
    />
    <TimelineItem
      v-for="(item, i) in items"
      :key="i"
      :event="item"
      :hide-right-if-same="hideRightIfSame"
      :display-arrow="i > 0"
      :hide-time-zone="hideTimeZone"
      :is-expand-content="isExpandContent"
      :expand-content-has-more-data="expandContentHasMoreData"
      :is-loading="isLoading"
    />
  </v-timeline>
</template>
<script>
import HeaderRow from '../../atoms/timeline/header-row';
import TimelineItem from './timeline-item';

export default {
  name: 'Timeline',
  components: {
    HeaderRow,
    TimelineItem,
  },
  props: {
    items: { type: Array, required: true },
    displayHeader: { type: Boolean, default: false },
    leftHeader: { type: String, default: null },
    rightHeader: { type: String, default: null },
    hideRightIfSame: { type: Boolean, default: false },
    hideTimeZone: { type: Boolean, default: false },
    expandContentHasMoreData: { type: Function, default: () => false },
    isLoading: { type: Boolean, default: false },
    isExpandContent: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
.timeline-container .v-timeline-item__inner-dot .v-icon {
  position: absolute;
  top: -16px;
  color: $ground;
}
.v-timeline::before {
  display: none !important;
}
</style>
<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
.timeline-container {
  font-size: 0.75rem;
  padding-right: 5px;
  padding-left: 5px;
  @include mq($from: md) {
    font-size: 0.875rem;
  }
  &.is-expand-content {
    padding-top: 0;
  }
}
</style>
