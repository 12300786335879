<template>
  <v-tabs
    show-arrows
    class="tabs"
    background-color="#00939B"
    dark
    :value="selectedIndex"
    @change="onChange"
  >
    <v-tabs-slider />

    <v-tab
      v-for="(item, i) in items"
      :key="i"
    >
      {{ item }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'ReelablesWebTabs',
  props: {
    items: { type: Array, default: () => [] },
    selectedIndex: { type: Number, required: true },
  },
  methods: {
    onChange(index) {
      this.$emit('change', index);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';
.tabs {
  margin-top: 10px;
  .v-tab {
    font-family: 'Bold-Font';
    text-transform: capitalize !important;
  }
}
</style>
