<template>
  <v-dialog
    :value="isVisible"
    :content-class="contentClass"
    persistent
    :fullscreen="isFullscreen"
    :attach="attach"
  >
    <slot />
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisible: { type: Boolean, default: false },
    isWide: { type: Boolean, default: false },
    isFullscreen: { type: Boolean, default: false },
    attach: { type: String, default: '' },
  },
  computed: {
    contentClass() {
      let className = 'dialogue-container';
      if (this.isFullscreen) {
        className += ' is-fullscreen';
      }
      if (this.isWide) {
        className += ' is-wide';
      }
      return className;
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_breakpoints.scss';
.dialogue-container {
  border-radius: 20px;
  max-width: 600px;
  min-height: 200px;
  background-color: $white;
  color: $reelables-light-grey;
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  padding: 20px;
  @include mq($from: sm) {
    padding: 30px;
  }
  @include mq($from: md) {
    padding: 30px;
  }
  &.is-fullscreen {
    max-width: 100%;
    min-height: 100%;
    border-radius: 0;
    padding: 0;
  }
  &.is-wide {
    max-width: 800px;
  }
}
</style>
