<template>
  <ItemsMap
    :is-dialogue-visible="isDialogueVisible"
    :dialogue-title="dialogueTitle"
    :locations="locations"
    :popup-title="popupTitle"
    :popup-button-title="popupButtonTitle"
    @close="() => isDialogueVisible = false"
    @backToList="backToShipmentsList"
    @clickedPopupButton="clickedPopupButton"
    @clickedClusteredItems="clickedClusteredItems"
    @clickedUnclusteredItem="clickedUnclusteredItem"
  >
    <ShipmentsTable
      slot="map-dialogue-content"
      class="shipments-table"
      :item-ids="clusteredShipmentIds"
    />
  </ItemsMap>
</template>
<script>
import ItemsMap from '../map/ItemsMap';
import ShipmentsTable from '../../components/organisms/shipments/shipments-table';
import { shipments } from '../../data/shipments-demo';
import { routeNames } from '../../constants';

export default {
  name: 'ShipmentsMap',
  components: {
    ItemsMap,
    ShipmentsTable,
  },
  data() {
    return {
      isDialogueVisible: false,
      clusteredShipmentIds: [],
      unclusteredShipment: null,
      popupTitle: null,
      popupButtonTitle: null,
    };
  },
  computed: {
    dialogueTitle() {
      return `${this.clusteredShipmentIds.length} shipments in cluster`;
    },
    locations() {
      return shipments
        .filter((shipment) => shipment.locations.items.length > 0)
        .map((shipment) => ({
          latitude: shipment.locations.items[0].latitude,
          longitude: shipment.locations.items[0].longitude,
          properties: {
            id: shipment.id,
            name: shipment.trackingRef,
            timestamp: shipment.locations.items[0].timestamp,
          },
        }));
    },
  },
  created() {
    // Redirect to home if shipments demo is disabled
    if (!this.$root.workspaceFeatures.shipmentsDemo) {
      this.$router.push({ name: routeNames.home });
    }
  },
  methods: {
    clickedPopupButton() {
      if (this.popupButtonTitle === 'Show shipments') {
        this.isDialogueVisible = true;
      } else {
        this.$router.push({
          name: routeNames.shipments.detail,
          params: { id: this.unclusteredShipment.id },
        }).catch(() => { });
      }
    },
    clickedClusteredItems(clusteredShipments) {
      this.popupTitle = `${clusteredShipments.length} shipments in cluster`;
      this.popupButtonTitle = 'Show shipments';
      console.log(clusteredShipments);
      this.clusteredShipmentIds = clusteredShipments.map((s) => s.id);
    },
    clickedUnclusteredItem(unclusteredShipment) {
      const selectedShipment = shipments.find(
        (s) => s.id === unclusteredShipment.id,
      );
      this.popupTitle = `${selectedShipment.trackingRef}`;
      this.popupButtonTitle = 'Go to shipment';
      this.unclusteredShipment = selectedShipment;
    },
    backToShipmentsList() {
      this.$router.push({ name: routeNames.shipments.list });
    },
  },
};

</script>
<style lang="scss" scoped>
.shipments-table {
  max-height: 300px;
}
</style>
