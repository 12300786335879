<template>
  <fragment>
    <v-app-bar
      :key="renderKey"
      :class="{
        'is-signed-in': isSignedIn
      }"
      class="header-wrap"
      app
      flat
      absolute
      :height="topBarHeight"
    >
      <v-container
        fluid
        class="header-container"
      >
        <v-layout>
          <img
            class="reelables-logo"
            :src="logoImage"
            :height="logoHeight"
            alt="Reelables logo"
            @keydown="clickLogo"
            @click="clickLogo"
          >
          <fragment v-if="isSignedIn">
            <v-spacer />
            <div
              v-if="shouldDisplayRefreshButton"
              class="refresh-wrapper"
            >
              <RefreshMessage
                :message="lastUpdatedText"
                prevent-url-reload
                is-small
                @refresh="refresh"
              />
            </div>
            <NavigationTabs
              v-if="displayNavigationTabs && $vuetify.breakpoint.mdAndUp"
              :include-all-tabs="includeAllNavTabs"
            />
            <v-spacer />
            <Menu />
          </fragment>
        </v-layout>
        <v-layout v-if="isSignedIn && $vuetify.breakpoint.smAndDown">
          <NavigationTabs
            v-if="displayNavigationTabs"
            :include-all-tabs="includeAllNavTabs"
          />
        </v-layout>
      </v-container>
    </v-app-bar>
    <YesNoDialogue
      :is-visible="isDialogueVisible"
      title="Do you want to switch on all features?"
      @no="() => isDialogueVisible = false"
      @yes="turnOnFeatures"
    />
  </fragment>
</template>
<script>
import ShortLogoImage from '../../../assets/images/reelables-r-logo.svg';
import LongLogoImage from '../../../assets/images/reelables-name-logo.svg';
import NavigationTabs from './navigation-tabs';
import Menu from './menu';
import RefreshMessage from '../../atoms/message/refresh-message';
import updateDateTimes from '../../../mixins/update-date-times';
import dateTimeHelper from '../../../mixins/date-time-helper';
import YesNoDialogue from '../dialogues/yes-no-dialogue';
import { routeNames } from '../../../constants';

export default {
  name: 'AppHeader',
  components: {
    NavigationTabs,
    Menu,
    YesNoDialogue,
    RefreshMessage,
  },
  mixins: [updateDateTimes, dateTimeHelper],
  data() {
    return {
      lastUpdatedTime: null,
      isRefreshing: false,
      clickCounter: 0,
      isDialogueVisible: false,
      renderKey: 1,
    };
  },
  computed: {
    logoImage() {
      return this.isSignedIn ? ShortLogoImage : LongLogoImage;
    },
    lastUpdatedText() {
      if (this.isRefreshing) return 'Refreshing...';
      const lastUpdated = {
        timer: this.timer,
        text: this.getLastSeen(this.lastUpdatedTime),
      };
      return `Last updated ${lastUpdated.text.toLowerCase()}`;
    },
    shouldDisplayRefreshButton() {
      return this.renderKey
        && this.$root.featureToggles.includeAppRefreshButton() && this.$store.getters.isMobileApp;
    },
    displayNavigationTabs() {
      return !this.$route.meta.noTopNav
        && this.renderKey
        && this.$store.getters['teams/hasSelectedTeam'];
    },
    includeAllNavTabs() {
      return this.renderKey && this.$root.featureToggles.includeNavigationBar();
    },
    logoHeight() {
      if (this.isSignedIn) {
        return this.$vuetify.breakpoint.smAndDown ? 35 : 45;
      }

      return 30;
    },
    topBarHeight() {
      if (this.isSignedIn) {
        if (!this.displayNavigationTabs) return 50;

        return this.$vuetify.breakpoint.smAndDown
          ? 90
          : 70;
      }

      return this.$vuetify.breakpoint.mdAndDown
        ? 80
        : 100;
    },
    isSignedIn() {
      return this.$store.state.auth.signedIn;
    },
  },
  created() {
    this.lastUpdatedTime = new Date().toISOString();
  },
  methods: {
    refresh() {
      this.isRefreshing = true;
      this.$store.dispatch('mobileApp/refresh');
    },
    clickLogo() {
      if (this.clickCounter === 0) {
        this.$router.push({ name: routeNames.home });
      }
      this.incrementCounter();
    },
    incrementCounter() {
      if (this.isSignedIn) {
        this.clickCounter += 1;
        if (this.clickCounter === 1) {
          setTimeout(() => { this.clickCounter = 0; }, 3000);
        } else if (this.clickCounter === 10) {
          this.isDialogueVisible = true;
          this.clickCounter = 0;
        }
      }
    },
    turnOnFeatures() {
      this.$store.commit('auth/LOG_OUT');
      // switch on features
      this.$root.switchOnAllFeatures();
      setTimeout(() => {
        this.$store.commit('auth/LOGIN');
        this.isDialogueVisible = false;
        this.renderKey += 1;
      }, 1000);
      // this.$store.dispatch('refreshData');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_breakpoints.scss';
@import '@/styles/_variables.scss';

.header-wrap {
  background-color: transparent !important;
  user-select: none;
  .header-container {
    position: relative;
    margin: 0;
    padding: 0;
    .reelables-logo {
      margin-left: 4px;
    }
  }
}
.refresh-wrapper {
  position: absolute;
  text-align: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @include mq($from: md) {
    top: -14px;
    left: calc(50% + 25px);
  }
}

</style>
<style lang="scss">
@import '@/styles/_breakpoints.scss';

.header-wrap {
  .v-toolbar__content {
    padding: 18px 16px;

    @include mq($from: md) {
      padding: 20px 25px 0;
    }
  }
  &.is-signed-in .v-toolbar__content {
    padding: 14px 14px 0px;

    @include mq($from: md) {
      padding: 36px 35px 0px 36px;
    }
  }
}
</style>
