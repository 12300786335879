<template>
  <MainWithDetailLayout
    :main-route-name="mainRouteName"
    :is-main-full-width="!isDetailScreen"
  >
    <VerticalContainer>
      <NotificationsTable
        :selected-id="$route.params.id"
      />
    </VerticalContainer>
  </MainWithDetailLayout>
</template>
<script>
import MainWithDetailLayout from '../../components/organisms/layout/main-with-detail-layout';
import NotificationsTable from '../../components/organisms/notifications/notifications-table';
import { routeNames } from '../../constants';
import VerticalContainer from '../../components/organisms/layout/vertical-container';

export default {
  name: 'NotificationsList',
  components: {
    VerticalContainer,
    NotificationsTable,
    MainWithDetailLayout,
  },
  computed: {
    mainRouteName() { return routeNames.notifications.list; },
    isDetailScreen() { return this.$route.name !== routeNames.notifications.list; },
  },
  created() {
    // Redirect to home if shipments demo is disabled
    if (!this.$root.workspaceFeatures.shipmentsDemo) {
      this.$router.push({ name: routeNames.home });
    }
  },
};
</script>
