<template>
  <div class="gateway-status-timeline-container">
    <InfiniteTimeline
      :items="items"
      :display-header="showHeader"
      :is-loading="isLoading"
      :has-more-data="hasMoreSpaceStatusData"
      :left-header="'Start'"
      :right-header="'End'"
      @moreData="getMoreData"
    />
  </div>
</template>
<script>
import { constructGatewayStatusTimelineItemsForSpace } from '../../../helpers/gateway-status-timeline.helper';
import InfiniteTimeline from '../../molecules/timeline/infinite-timeline';

export default {
  name: 'GatewayStatusTimeline',
  components: {
    InfiniteTimeline,
  },
  props: {
    spaceId: { type: String, required: true },
    facilityId: { type: String, required: true },
  },
  computed: {
    facility() {
      return this.$store.state.facilities.facilities.find(({ id }) => id === this.facilityId);
    },
    space() {
      return this.facility.spaces
        .find(({ spaceId }) => spaceId === this.spaceId);
    },
    statusHistory() {
      return this.space.statusHistory;
    },
    items() {
      return constructGatewayStatusTimelineItemsForSpace(
        this.currentGatewayStatus,
        this.facility.timeZone,
        this.statusHistory,
      );
    },
    showHeader() {
      return this.items.length > 0;
    },
    isLoading() {
      return this.$store.state.facilities.isLoadingSpaceStatuses;
    },
    hasMoreSpaceStatusData() {
      if (this.isLoading) {
        return false;
      }
      return !!(this.statusHistory || {}).nextToken;
    },
    currentGatewayStatus() {
      return this.space.status;
    },
  },
  created() {
    this.getSpaceStatuses();
  },
  methods: {
    getSpaceStatuses() {
      this.$store.dispatch('facilities/getSpaceStatuses', { spaceId: this.spaceId, facilityId: this.facilityId });
    },
    getMoreData() {
      this.getSpaceStatuses();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.gateway-status-timeline-container {
  height: 100%;
  box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
}
</style>
