import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import GatewayStatus from '../views/gateway/GatewayStatus';
import Settings from '../views/settings/Settings';

import TeamHome from '../views/TeamHome';
import NotFound from '../views/NotFound';
import assetRoutes from './asset-routes';
import zoneRoutes from './zone-routes';
import teamRoutes from './team-routes';
import dashboardRoutes from './dashboard-routes';
import facilitiesRoutes from './facilities-routes';
import nbIotRoutes from './nb-iot-routes';
import { routeNames } from '../constants';
import pageViewTracking from '../mixins/event-tracking/mix-panel/page-view-tracking';
import shipmentRoutes from './shipment-routes';
import notificationRoutes from './notification-routes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: routeNames.home,
    component: Home,
  },
  {
    path: '/not-found',
    name: routeNames.notFound,
    component: NotFound,
  },
  {
    path: '/gateway',
    name: routeNames.gateway.status,
    component: GatewayStatus,
    props: (route) => ({ ...route.query, ...route.params }),
    meta: {
      allowTokenAuth: true, // allow route to be viewed with token in the URL
      noLayout: true,
    },
  },
  ...nbIotRoutes,
  {
    path: '/:teamId',
    name: routeNames.teamHome,
    redirect: { name: routeNames.assets.list },
    props: true,
    component: TeamHome,
    children: [
      ...notificationRoutes,
      ...shipmentRoutes,
      ...assetRoutes,
      ...zoneRoutes,
      ...teamRoutes,
      ...dashboardRoutes,
      ...facilitiesRoutes,
      {
        path: 'settings',
        name: routeNames.settings,
        component: Settings,
        meta: {
          noTopNav: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Mixpanel analytics for tracking page views
  if (to.fullPath !== from.fullPath) {
    pageViewTracking.trackPageView(to);
  }

  next();
});

export default router;
