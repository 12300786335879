function constructSpacesString(spaces) {
  const spaceNames = spaces.map(({ spaceName }) => spaceName);
  const uniqueSpaceNames = Array.from(new Set(spaceNames)); // Remove duplicates.
  return uniqueSpaceNames.join(', ');
}

function constructFacilitySpacesString(spaces, facilityName) {
  return `${facilityName} (${constructSpacesString(spaces)})`;
}

function constructSpacesNotInfacility(spacesNotInFacility) {
  return (spacesNotInFacility.length > 0 ? `, ${constructSpacesString(spacesNotInFacility)}` : '');
}

/**
 * Constructs a string of spaces and facilities that an asset is currently in
 * @param {*} asset - asset object
 * @param {*} notCurrentlyInText - text to display if asset is not currently
 *  in any space or facility
 * @returns
 */
export default function constructAssetCurrentlyIn(asset, notCurrentlyInText = '-') {
  if ((asset.inSpaces || []).filter(({ exitedOn }) => !exitedOn).length === 0
    && (asset.inFacilities || []).filter(({ exitedOn }) => !exitedOn).length === 0) {
    return notCurrentlyInText;
  }

  const inSpaces = [...(asset.inSpaces || [])]
    .filter(({ exitedOn }) => !exitedOn)
    .sort((a, b) => a.enteredOn.localeCompare(b.enteredOn));

  const inFacilities = [...(asset.inFacilities || [])]
    .filter(({ exitedOn }) => !exitedOn)
    .sort((a, b) => a.enteredOn.localeCompare(b.enteredOn))
    // remove duplicate facilities based on name
    .filter((facility, index, self) => self
      .findIndex((f) => f.facilityName === facility.facilityName) === index);

  if (inFacilities.length > 0) {
    const spacesNotInFacility = inSpaces
      .filter(({ facilityId }) => !inFacilities.some(({ facilityId: fId }) => fId === facilityId));

    return inFacilities
      .map(({ facilityId, facilityName }) => {
        const spacesInFacility = inSpaces.filter(({ facilityId: fId }) => fId === facilityId);

        if (spacesInFacility.length > 0) {
          return constructFacilitySpacesString(spacesInFacility, facilityName, facilityId);
        }

        return facilityName;
      }).join(', ') + constructSpacesNotInfacility(spacesNotInFacility);
  }

  return constructSpacesString(inSpaces);
}
