import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { justNow } from '../constants';

TimeAgo.addLocale(en);
// Create relative date/time formatter.
const timeAgo = new TimeAgo('en-GB');

function updateLastSeen(newDate, lastSeen) {
  if (lastSeen === null) {
    return newDate;
  } if (newDate > lastSeen) {
    return newDate;
  }

  return lastSeen;
}

export function formatDate(date, isShort = false) {
  let formated = timeAgo.format(date, isShort ? 'twitter-minute-now' : 'round-minute');

  // timeAgo.format returns 'just now' for dates within the last minute
  // and for consistency we want to return the justNow constant instead
  if (formated === 'just now') {
    formated = justNow;
  }

  return formated;
}

export default {
  methods: {
    getLastSeen(item, isShort = false) {
      let lastSeen = null;

      if (!item) {
        return lastSeen;
      }

      const {
        locations, custody, temperatures, inSpaces, inFacilities,
      } = item;

      if (typeof item === 'string') {
        lastSeen = new Date(item);
      } else if (typeof item === 'object') {
        if (item.lastSeen) {
          lastSeen = updateLastSeen(new Date(item.lastSeen), lastSeen);
        }
        if (locations?.items?.length > 0) {
          lastSeen = updateLastSeen(new Date(locations.items[0].timestamp), lastSeen);
        }
        if (custody?.items?.length > 0) {
          lastSeen = updateLastSeen(new Date(custody.items[0].timestamp), lastSeen);
        }
        if (temperatures?.items?.length > 0) {
          lastSeen = updateLastSeen(new Date(temperatures.items[0].timestamp), lastSeen);
        }
        if (
          (inFacilities || []).filter((d) => !d.exitedOn).length > 0
          || (inSpaces || []).filter((d) => !d.exitedOn).length > 0) {
          lastSeen = justNow;
        }
      }

      // if last seen is a date object, format it
      if (lastSeen && typeof lastSeen === 'object') {
        lastSeen = formatDate(lastSeen, isShort);
      }

      return lastSeen;
    },
    getFirstSeen(asset, isShort = false) {
      let firstSeenMs = Infinity;

      [
        ...(asset.inFacilities || []).filter((item) => !item.exitedOn),
        ...(asset.inSpaces || []).filter((item) => !item.exitedOn),
      ].forEach((item) => {
        const enteredOnMs = new Date(item.enteredOn).getTime();
        if (enteredOnMs < firstSeenMs) {
          firstSeenMs = enteredOnMs;
        }
      });

      if (firstSeenMs < Infinity) {
        return formatDate(new Date(firstSeenMs), isShort);
      }

      return null;
    },
    getLastMoved(asset) {
      let lastMoved = null;

      if (!asset) {
        return lastMoved;
      }

      const { inSpaces } = asset;

      if (inSpaces && Array.isArray(inSpaces) && inSpaces.length > 0) {
        lastMoved = new Date([...inSpaces]
          .sort((a, b) => new Date(b.enteredOn) - new Date(a.enteredOn))[0].enteredOn);
      }

      if (lastMoved !== null) {
        lastMoved = timeAgo.format(lastMoved);
      }

      return lastMoved;
    },
    getTimeAgo(isoTimestamp) {
      return timeAgo.format(new Date(isoTimestamp), 'round-minute');
    },
  },
};
